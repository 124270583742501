/* newww */
.bgImage{
    position: absolute;
    width: 100%;
    height: 100vh;
}
.loginDiv{
    width: 100%;
    height: 100% ;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: absolute;
}
.logoSize1{
    width: 50px;
}
.loginInner{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 1px 0px 30px #808080;
}
.loginLeft{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow:inset 1px 1px 17px grey;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
.loginDiv1{
    width: 40%;
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}
.loginDiv2{
    width: 40%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}
.log-parts{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    align-items: flex-start;
    
}
.logLogo{
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
.greybg{
    position: absolute ;
    width: 40%;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.logoSize1{
    width: 160px;
    margin-right: 20px;
    z-index: 10;
}
.logoSize2{
    width: 250px;
    /* display: none; */
}
.compName{
    font-size: 1.8rem;
    font-weight: 600;
    color: #fff;
}
.log-parts span{
    font-size: 1.8rem;
    font-weight: 600;
    color: #007BC9;
}
.log-parts1{
    width: 90%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
}
.log-parts-OTP{
    width: 100%;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative; 
}
.log-parts-OTP>span{
    font-size: 0.8rem;
    color: #007BC9;
    font-weight: 800;
}
.log-parts1New{
    /* border: 1px solid #007BC9; */
    width: 53%;
    height: 53px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.input-11,
.input-22,
.input-33,
.input-44 {
    width: 13%; 
    height: 44px;
    font-family: GOTHIC;
}
.fmeOtpNew{
    width: 90%;
    height: 100%;
}
.log-parts1 span{
    font-size: 0.8rem;
    color: #007BC9;
    font-weight: 800;
}
.myCustInp{
    width: 100%;
    height: 38px;
    border: 1px solid #007BC9;
    padding-left: 5px;
    border-radius: 6px;
    background-color: transparent;
    color: #007BC9;
    letter-spacing: 1px;
}
.myCustInp::placeholder{
    color: #007BC9;
    padding-left: 5px;
    font-size: 0.8rem;
    letter-spacing: 1px;
}
input:focus{
    outline: none;
}
.eyeIcon{
    position: absolute;
    right: 10px;
    top: 38px;
    color: #727376;
    cursor: pointer;
}
.log-parts2{
    width: 100%;
    height: auto;
    display: flex;
}
.btnloginBtn{
    min-width: auto;
    height: 33px;
    background-color: #007BC9;
    color: #fff;
    cursor: pointer;
    border: 1px solid #007BC9;
    border-radius: 7px;
    font-size: 0.8rem;
    margin-right: 30px;
    padding-left: 17px;
    padding-right: 17px;
}
.btnforgotBtn{
    width: auto;
    height: 33px;
    background-color: #007BC9;
    color: #fff;
    cursor: pointer;
    border: 1px solid #007BC9;
    border-radius: 7px;
    font-size: 0.8rem;
    padding-left: 17px;
    padding-right: 17px;
}
.btnloginBtn:hover{
    width: auto;
    height: 33px;
    background-color: #fff;
    color: #007BC9;
    cursor: pointer;
    border: 1px solid #007BC9;
    border-radius: 7px;
    font-size: 0.8rem;
    margin-right: 30px;
    
    box-shadow: 0px 0px 5px #007BC9;
}
.btnforgotBtn:hover{
    width: auto;
    height: 33px;
    background-color: #fff;
    color: #007BC9;
    cursor: pointer;
    border: 1px solid #007BC9;
    border-radius: 7px;
    font-size: 0.8rem;
    margin-right: 30px;
    padding-left: 17px;
    padding-right: 17px;
    box-shadow: 0px 0px 5px #007BC9;
}
@media only screen and (max-width: 700px) {
.loginDiv2{
    width: 80%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}
}








