.profile-container{
    border: 4px solid #007BC9;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 50%;
}

.profile-dummy-image{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    /* background-color: #f5f5f5; */
    padding: 4px;
}

.profile-title{
    width: 100%;
    /* border: 1px solid #007BC9; */
}

.profile-title h1 {
    font-size: 20px;
    font-weight: 800;
    color: #007BC9;
}

.profile-title span {
    font-size: 15px;
    font-weight: 800;
}