.Feedback-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    /* border: 5px solid rgb(236, 233, 9); */
    background-image: url('./../../static/images/icon/sidebar/updated/yb.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 20px;
}

.feedback-Thankyou-form-container{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 400px;
    min-height: 220px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    animation: scaleIn 2s ease 0s 1 normal forwards;
}

.feedback-form-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 400px;
    margin: auto;
    text-align: center;
}

.feedback-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.errorText{
    color: red;
    font-size: 12px;
    font-weight: 600;
}

.feedback-subtitle {
    font-size: 0.9rem;
    margin-bottom: 20px;
    color: #555;
}

.stars {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.star {
    font-size: 3rem;
    color: rgb(177, 173, 173);
    cursor: pointer;
    padding: 0 5px;
}

.star.selected {
    color: #FFA000;
}

.feedback-input {
    width: 100%;
    height: 80px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.9rem;
}

.feedback-input:focus {
    outline: none;
    border-color: #FFA000;
}

.char-limit {
    font-size: 0.8rem;
    color: #888;
    margin-bottom: 15px;
}

.anonymous-checkbox {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

input[type="radio"] {
    display: none;
}

/* Style the custom radio button */
.custom-radio {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ddd;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}

/* When the radio is checked, change the color */
input[type="radio"]:checked+.custom-radio {
    border-color: #FFA000;
    background-color: #FFA000;
}

/* Style the inner circle for checked radio */
.custom-radio::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease;
}

/* Show the inner circle when checked */
input[type="radio"]:checked+.custom-radio::after {
    opacity: 1;
}


.anonymous-checkbox label {
    display: flex;
    font-size: 0.9rem;
    margin-left: 5px;
    text-align: start;
}

/* checkbox */

input[type="checkbox"] {
    display: none;
}

/* Style the custom radio button */
.custom-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    /* border-radius: 50%; */
    border: 2px solid #ddd;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}

/* When the radio is checked, change the color */
input[type="checkbox"]:checked+.custom-checkbox {
    border-color: #FFA000;
    background-color: #FFA000;
}

/* Style the inner circle for checked radio */
.custom-checkbox::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    /* border-radius: 50%;/ */
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease;
}

/* Show the inner circle when checked */
input[type="checkbox"]:checked+.custom-checkbox::after {
    opacity: 1;
}


.anonymous-checkbox label {
    display: flex;
    font-size: 0.9rem;
    margin-left: 5px;
    text-align: start;
}

.submit-button {
    width: 100%;
    background-color: #0f438f;
    color: white;
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0f438f;
}

.skip-button {
    margin-top: 10px;
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 0.9rem;
}

.skip-button:hover {
    color: #555;
}

/* Media Queries for Responsiveness */
@media (max-width: 600px) {
    .feedback-form-container {
        width: 100%;
        padding: 15px;
        margin: 20px;
    }
    .feedback-Thankyou-form-container{
        width: 100%;
    }

    .feedback-title {
        font-size: 1.3rem;
    }

    .feedback-subtitle {
        font-size: 0.8rem;
    }

    .star {
        font-size: 1.8rem;
    }

    .feedback-input {
        height: 70px;
        font-size: 0.85rem;
    }

    .submit-button {
        font-size: 0.9rem;
        padding: 8px;
    }

    .skip-button {
        font-size: 0.8rem;
    }
}



@keyframes scaleIn {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}