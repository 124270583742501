.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 0.5rem;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
/* .model_container{
  border: 1px solid black;
} */


.modal-header{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007bc9;
  text-align: center;
  background-color: #ffe000;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}

.modal-footer{
  background-color: #ffe000;
  border-radius: 0px 0px 10px 10px;
}

.heading {
    /* font-size: 20px; */
    /* margin-top: 7px; */
}

.modal-title {
    margin-bottom: -10px !important;
}


.model_header .btn-close {
  cursor: pointer;
  border-radius: 25px;
  height: 40px;
  width: 40px;
  position: absolute;
  right: -18px;
  top: -16px;
  border: none;
  background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='rgb(0, 123, 201)' d='M10 8.586L2.929 1.515L1.515 2.929L8.586 10l-7.071 7.071l1.414 1.414L10 11.414l7.071 7.071l1.414-1.414L11.414 10l7.071-7.071l-1.414-1.414L10 8.586z'/%3E%3C/svg%3E") center/1em auto no-repeat !important;
}


.custom-new-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.checkbox-input {
  display: none; /* Hide the default checkbox */
}

.checkbox-mark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff; /* Border color */
  border-radius: 4px; /* Rounded corners */
  margin-right: 10px;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.checkbox-mark::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff; /* Background color when checked */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s;
  border-radius: 2px; /* Rounded corners for the inner box */
}

.checkbox-input:checked + .checkbox-mark {
  background-color: #007BC9; /* Background color when checked */
  border-color: #007BC9; /* Darker border color when checked */
}

.checkbox-input:checked + .checkbox-mark::after {
  transform: translate(-50%, -50%) scale(1); /* Show the inner box */
}


.custom-radio-button {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  font-size: 0.8em;
  font-weight: 600;
  user-select: none;
}

.custom-radio-button__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio-button__checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid #ccc;
  
}

.custom-radio-button:hover .custom-radio-button__input ~ .custom-radio-button__checkmark {
  background-color: #ccc;
}

.custom-radio-button__input:checked ~ .custom-radio-button__checkmark {
  background-color: #007BC9;
  border-color: #007BC9;
}

.custom-radio-button__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-button__input:checked ~ .custom-radio-button__checkmark:after {
  display: block;
}

.custom-radio-button .custom-radio-button__checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.truck-marker {
  width: 50px;
  height: 50px;
  background: url('../../../src/static/images/icon/sidebar/updated/Truck.gif') no-repeat center center;
  background-size: contain;
  border-radius: 50%;
}