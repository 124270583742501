.tripTop-container{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    height: 40px;
    margin-bottom: -2px;
    z-index: -1;
}

.tripTipbox{
    align-items: center;
    background-color: #007bc9;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* box-shadow: 0 0 10px grey; */
    display: flex;
    height: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    text-align: center;
    width: 160px;
}


.ListView-container{
    position: absolute;
    top: 0%;
    left: 1%;
    width: 400px;
    height: calc(100vh - 260px);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    /* padding: 20px; */
    margin-top: 20px;
    z-index: 1000;
}

.ListView-header{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007bc9;
    text-align: center;
    background-color: #ffe000;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    height: 40px;
  }

.ListView-header .btn-close {
    cursor: pointer;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    position: absolute;
    right: -18px;
    top: -16px;
    border: none;
    background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='rgb(0, 123, 201)' d='M10 8.586L2.929 1.515L1.515 2.929L8.586 10l-7.071 7.071l1.414 1.414L10 11.414l7.071 7.071l1.414-1.414L11.414 10l7.071-7.071l-1.414-1.414L10 8.586z'/%3E%3C/svg%3E") center/1em auto no-repeat !important;
}

.ListView-body{
    width: inherit;
    height: calc(100% - 60px);
    /* border: 1px solid red; */
    overflow-y: auto;
    padding: 10px;
}

.route-address-container{
    margin-bottom: 20px;
    color: #000;
}

.arrow-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}

.eta-status-left{
    width: 40%;
    font-size: 11px;
    margin-top: -15px;
    color: #007bc9;
    font-weight: 600;
    margin-left: 20px;
    position: absolute;
    left: 0%;
    display: flex;
    justify-content: flex-end;
    /* border: 1px solid red; */
}

.eta-status-right{
    width: 45%;
    font-size: 11px;
    margin-top: -5px;
    color: #007bc9;
    font-weight: 600;
    margin-left: 20px;
    position: absolute;
    right: 0%;
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid red; */
}

.addressCard{
    width: 100%;
    min-height: 40px;
    max-height: auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 5px;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.delivered-status{
    border: 1px solid green;
    border-radius: 5px;
    width: fit-content;
    padding: 0px 3px;
    height: 18px;
    position: absolute;
    top: -10px;
    right: 0px;
    background-color: white;
    color: green;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slot-status{
    border: 1px solid green;
    border-radius: 5px;
    width: 45%;
    height: 18px;
    position: absolute;
    top: -10px;
    left: 0px;
    background-color: white;
    color: green;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pit-delivered-right{
    border: 1px solid #007bc9;
    border-radius: 5px;
    width: 50%;
    height: 18px;
    position: absolute;
    top: -10px;
    right: 0px;
    background-color: white;
    color: #007bc9;
    font-size: 11px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-active{
    background-color: #FFFD37;
    color: #007bc9;
    font-weight: 600;
}

.delivered{
    background-color: #5CB338;
    color: white;
    font-weight: 600;
}

.skippedTrip {
    background-color: #C40C0C;
    color: white;
    font-weight: 600;
}

.location-mark{
    border: 1px solid #ccc;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.address{
    width: calc(100% - 20px);
    height: auto;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
}

.ListView-header-title{
    font-weight: 600;
}

.ListView-footer {
    height: 20px;
    background-color: #ffe000;
    border-radius: 0px 0px 10px 10px;
}

.vehicle-container{
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0px 5px 5px 5px;
    margin-bottom: 5px;
}

.vehicle-container-height{
    height: 30px;
}

.vehicle-header{
    background-color: #007bc9;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-bottom: 1px solid #ccc;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 5px;
    margin-left: -5px;
    margin-right: -5px;
    position: relative;
}

.vehicle-detail {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.collasing-btn{
    position: absolute;
    right: 8px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.SaveTrip-btn{
    position: absolute;
    top: 1.5%;
    right: 48%;
    z-index: 1000;
    background-color: #ffe000;
    padding: 5px 20px;
    border-radius: 6px;
    border: 1px solid #ffe000;
    font-size: 14px;
    color: #007bc9;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}


@media screen and (max-width: 768px) {
    .ListView-container{
        transform: scale(0.8);
        left: -6%;
        height: calc(100vh - 100px);
        margin-top: 0px;
    }
    
}

@media screen and (max-width: 480px) {
    .ListView-container {
        transform: scale(0.8);
        left: -6%;
        height: calc(100vh - 100px);
        margin-top: 0px;
    }
}

@media screen and (max-width: 360px) {
    .ListView-container {
        transform: scale(0.8);
        left: -9%;
        height: calc(100vh - 100px);
        margin-top: 0px;
    }
}