@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300&family=Gothic+A1:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300&family=Gothic+A1:wght@600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300&family=Gothic+A1:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@800&family=Montserrat:wght@100&family=Open+Sans:wght@800&family=Tilt+Warp&display=swap');
span,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
select,
div {
    font-family: 'Bai Jamjuree', sans-serif !important;
}

#map-container {
    height: 100%;
    width: 100%;
    /* temporarily set the background color so we can tell where the map container is positioned */
    background-color: lightgrey;
}

.inputCustom {
    width: 200px;
    height: 40px;
    color: #727376;
    font-weight: 700;
}

.inputCustom-group{
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    width: 100%;
    height: 40px;
    background-color: #fff;
}

.selectSearchCustom{
    width: 50%;
    height: 38px;
    border: none;
    border-right: 1px solid #ced4da;
    border-radius: .25rem 0rem 0rem .25rem ;
    padding: 10px;
    font-size: 0.7rem;
    line-height: 1.5;
    color: #495057;
}

.selectSearchCustom:focus{
    outline: none;
}

/* Styling for the dropdown options */
.selectSearchCustom option {
    /* font-size: 0.9rem;
    color: #495057;
    background-color: #fff;
    padding: 4px;
    text-align: left;
    border: 1px solid yellow; */
  }
  
  /* Hover effect for options in the dropdown */
  .selectSearchCustom option:hover {
    background-color: #f1f1f1;
  }
  
  /* Disabled option styling */
  .selectSearchCustom option:disabled {
    color: #999;
    background-color: #f9f9f9;
  }

.inputCustom-group:focus-within {
    color: #727376;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.inputSearchCustom {
    width: 200px;
    height: 38px;
    padding: 10px !important;
    border-radius: 5px !important;
    font-size: 0.7rem !important;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    border: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.inputSearchCustom::placeholder {
    font-size: 0.75rem;
}

.is-invalid {   
    border: 1px solid red !important;
    outline: 1px solid red !important;
}

.invalid-feedback{
    width: 200px;
    color: red;
    font-size: 12px;
    font-family: 'Bai Jamjuree', sans-serif !important;
}

a {
    text-decoration: none;
}

.trackingMap{
    width: 100% ;
    height: 100vh ;
}
a:hover {
    color: #ff7200;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

*::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}

.tt2 {
    font-size: 1rem;
    font-weight: 600;
}

.btn-orange {
    background-color: #FFE000;
    color: #007BC9;
}

.btn-outline-orange {
    background-color: #FFF;
    color: #FF7200;
    border: 1px solid #FF7200;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-outline-orange:hover {
    background-color: #FF7200;
    color: #FFF;
}

.flex-algn-cent {
    display: flex;
    align-items: center;
}

.flex-jc-end {
    display: flex;
    justify-content: flex-end;
}

.flex-jc-center {
    display: flex;
    justify-content: center;
}

.flex-inline {
    display: inline-flex !important;
}

.collapse-view {
    height: 35px;
    overflow: hidden;
    position: relative;
}

.collapse-view>.collapsable_icon {
    position: absolute;
    right: 15px;
    top: -3px;
    height: 35px;
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
}

.collapse-view>.collapsable_icon:after {
    content: "\25be";
    color: white;
    font-weight: bold;
}

.collapse-view.open {
    overflow: unset;
}

.reduceMargin {
    margin-bottom: 1rem !important;
}

.collapse-view.open>.collapsable_icon:after {
    content: "\25be";
    transform: rotate(180deg)
}

/* #region Contract Detail */
.contract-tbl {
    width: 100%;
}

.contract-tbl>table,
.contract-tbl1>table {
    width: 100%;
    border: 1px solid #E5E5E5;
}

.contract-tbl>table,
.contract-tbl>table tr,
.contract-tbl>table td,
.contract-tbl>table th,
.contract-tbl1>table,
.contract-tbl1>table tr,
.contract-tbl1>table td,
.contract-tbl1>table th {
    border-collapse: collapse;
}

.contract-tbl>table th {
    padding: 10px 15px;
    background-color: #F2F2F2;
    font-weight: unset;
    font-size: 1rem;
    text-align: right;
    width: 15%;
    border-right: 1px solid #E5E5E5;
}

.contract-tbl1>table th {
    padding: 10px 15px;
    background-color: #F2F2F2;
    font-weight: unset;
    font-size: 1rem;
    font-weight: 600;
    border: 1px solid #E5E5E5;
    color: #000;
}

.contract-tbl1>table td {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #E5E5E5;
    color: #000;
}

.contract-tbl>table td {
    padding: 10px;
    width: 85%;
    color: #FF7200;
    font-weight: 600;
    font-size: 1rem;
}

.view_dc_btn {
    position: absolute;
    right: 10px;
    font-size: 0.8rem;
    font-weight: 100;
    text-decoration: underline;
    color: #2D8FB8;
    cursor: pointer;
}

.icncc {
    font-size: 1.6rem;
    margin-right: 0.5rem;
}

/* #endregion Contract Detail */

/*ddzone styling*/
#ddzone_drop-region {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 10px;
    text-align: center;
}

#ddzone_drop-region>.ddzone_drop-con {
    border: 2px dashed #727376;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    transition: .3s;
}

#ddzone_drop-region>.ddzone_drop-con:hover,
#ddzone_drop-region>.ddzone_drop-con.ddzone_hover {
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
}

#ddzone_drop-region>.ddzone_drop-con>img {
    width: 100px;
}

#ddzone_drop-region>.ddzone_drop-con>p {
    margin: 5px;
    font-weight: 600;
    color: #727376;
}

#ddzone_drop-region>.ddzone_drop-con>div {
    font-weight: 600;
    color: #727376;
}

#ddzone_image-preview {
    margin-top: 20px;
}

#ddzone_image-preview .ddzone_image-view {
    display: inline-block;
    position: relative;
    margin-right: 13px;
    margin-bottom: 13px;
}

#ddzone_image-preview .ddzone_image-view img {
    max-width: 100%;
}

#ddzone_image-preview .ddzone_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.5);
}

/*ddzone styling close*/

label.labelcc {
    font-weight: 600;
    width: 100%;
    margin-bottom: 0 !important;
    text-align: start;
    color: #000;
}

label.labelcc-new {
    font-weight: 600;
    width: 100%;
    margin-bottom: 0 !important;
    position: relative;
    left: 15px;
    color: #000;
}

.table-responsive {
    width: 100%;
    margin: 10px;
}

table.bordered_table {
    width: 100%;
}

table.bordered_table,
table.bordered_table th,
table.bordered_table td {
    border: 1px solid #727376;
    border-collapse: collapse;
    padding: 2px;
}

table.bordered_table th {
    padding: 5px;
    text-align: start;
}

table.bordered_table th>span.thd {
    font-size: 0.75rem;
    color: #888;
}

table.bordered_table th>h2.tht {
    font-size: 0.9rem;
    color: #3a3b3c;
    margin-bottom: 0;
}

.doc_container>.doc_container_head {
    height: 35px;
    background: #FF7200;
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-size: 1rem;
}

.data-cont>.data-cont-item {
    position: relative;
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    margin: 35px 0;
    box-shadow: 2px 2px 12px 0px rgb(0 0 0 / 12%);

}

.back-color {
    background-color: #edebeb !important;
}

.mg-btm {
    margin-top: 35px !important;
}

.mg-btm-15 {
    margin-bottom: 14px !important;
}

.data-cont>.data-cont-item>.sno_txt {
    position: absolute;
    background: #FFF;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    top: -10px;
    left: -10px;
    border: 2px solid #727376;
    z-index: 1;
    font-size: 10px;
    font-weight: 600;
}

.data-cont>.data-cont-item>.input_div {
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    top: -10px;
    right: -10px;
    border: 2px solid #727376;
    z-index: 1;
    font-size: 10px;
    font-weight: 600;
}

.data-cont>.data-cont-item>.input_div>.input_checkbox {
    position: absolute;
    cursor: pointer;
    width: 1px;
    height: 20px;

}

.data-cont>.data-cont-item>.input_div>.input_checkbox:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: -7px;
    right: -19px;
    border: 4px solid #ff7200;
    border-radius: 20px;
    background-color: #ff7200;
    transition: all 0.2s linear;
}


.data-cont>.data-cont-item>.input_div>.input_checkbox:before {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: -7px;
    right: -18px;
    border-radius: 20px;
    border: 4px solid #727376;
    background-color: #fff;
}


.data-cont>.data-cont-item>.input_div>.input_checkbox:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border: solid #FFCB9A;
    border-width: 0 0px 0px 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    position: absolute;
    top: 0px;
    left: 30px;
    transition: all 0.2s linear;

}

.data-cont>.data-cont-item>.input_div>.input_checkbox:checked:after {
    content: "";
    display: block;
    width: 7px;
    height: 14px;
    border: solid #fff;
    border-width: 0 5px 5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0px;
    left: 2px;
}

.data-cont>.data-cont-item>.data_item_content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    padding: 1px;
}

.data-cont>.data-cont-item>.data_item_content>div {
    width: calc(20% - 2px);
    background: #FFF;
    padding: 5px;
    margin: 0 1px;
    flex-wrap: wrap;
}

.ex-div {
    position: absolute;
    top: 0px;
    right: 0;
    width: calc(3% - 2px);
    height: 130px;

}

.data-cont>.data-cont-item>.data_item_content>.data_item_contnet_1 {
    width: 16.5%;
    background: #FFF;
    padding: 5px;
    margin: 0 1px;
}

.data-cont>.data-cont-item>.data_item_content.set_height>div {
    height: 50px;
    position: relative ! important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around !important;
    border-right: 1px solid #f3f3f3;
    flex-wrap: unset;
}

.data-cont>.data-cont-item>.data_item_content.set_height_xxl>div {
    height: 70px;
    position: relative ! important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around !important;
    flex-wrap: unset;
}

.data-cont>.data-cont-item>.data_item_content.setColor>div {
    background-color: #f6f6f6 !important;
    border-right: 1px solid #e8e7e7;
}

.set-bg {
    background-color: #f6f6f6 !important;
}

.data-cont>.data-cont-item>.data_item_content.setColor>div:last-child {
    background-color: #f6f6f6 !important;
    border-right: none;
}

.data-cont>.data-cont-item>.data_item_content.set_height_xxxl>div {
    height: 75px;
    position: relative ! important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around !important;
}

.data-cont>.data-cont-item>.data_item_content>div.wd_5 {
    width: calc(2.5% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_10 {
    width: calc(10% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_15 {
    width: calc(15% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_20 {
    width: calc(20% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_25 {
    width: calc(25% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_30 {
    width: calc(30% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_35 {
    width: calc(35% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_40 {
    width: calc(40% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_50 {
    width: calc(50% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_75 {
    width: calc(75% - 2px);
}

.data-cont>.data-cont-item>.data_item_content>div.wd_100 {
    width: calc(100% - 2px);
}

.centre-me {
    text-align: center;
}

.more-big {
    font-size: 1.1rem !important;
    color: red;
}

.pd-l {
    padding-left: 20px !important;
}

.pd-r {
    padding-right: 20px !important;
}

.btn_element {
    background-color: #727376;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.black_color {
    color: #000 !important;
    font-size: 0.7rem;
}

.orange_color {
    color: #FF7200 !important;
}

.grey_color {
    color: #727376 !important;
    font-size: 0.75rem;
}

.green_color {
    color: green !important;
}

.red_color {
    color: red !important;
}

.white_color {
    color: #FFF !important;
}

.menu-nav>li>a>i.ssicon {
    color: #727376;
    font-size: 2rem !important;
    margin: 0 10px !important;
}

.font-bold {
    font-weight: 600 !important;
}

.font-sm {
    font-size: 0.7rem !important;
}

.font-xsm {
    font-size: 0.6rem !important;
}

.font-md {
    font-size: 0.8rem !important;
}

.font-xmd {
    font-size: 0.9rem !important;
}

.font-lg {
    font-size: 1rem !important;
}

.font-xl {
    font-size: 1.2rem !important;
}

.font-xxl {
    font-size: 1.5rem !important;
}

.ccIcon {
    margin: 0 5px;
    font-size: 1rem;
    cursor: pointer;
}

.cals:active {
    margin: 0 5px;
    font-size: 1rem;
    cursor: pointer;
    color: green;
}

.scIcon {
    margin: 0 5px;
    font-size: 1rem;
    cursor: pointer;
    color: red;
}

.pinkk {
    color: pink !important;
}

.cals {
    margin: 0 5px;
    font-size: 1rem;
    cursor: pointer;
    color: black;
}

/*#region Trip Analysis CSS*/
.bg_black {
    background: #4a4747;
    color: #fff;
}

.table-responsive {
    background: #fff;
    margin-bottom: 2rem;
    border-radius: calc(.15rem - 1px);
}

.table-responsive>table.cctbl {
    width: 100%;
}

table.cctbl,
.cctbl td,
.cctbl tr,
.cctbl th {
    /* border: 1px solid #888; */
    text-align: center;
    border-collapse: collapse;
    border-radius: 5px;
}

.cctbl th {
    padding: 5px 0px;
    font-size: 12px;
}

table.cctbl td {
    vertical-align: top;
    width: 33.33%;
}

.dd_sec {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin: -2px;
    border-bottom: 1px solid #888;
    font-weight: 600;
    font-size: 1.3rem;
}

.dd_sec span {
    font-weight: 200;
    font-size: 11px;
    display: block;
    /* color: #FFF !important; */
}

.dd_sec>div {
    width: 100%;
    padding: 4px;
}

.dd_sec>div:first-child {
    border-right: 1px solid #888;
}

.dd_sec>div:last-child {
    border-left: 1px solid #888;
    margin-left: -1px;
}

.dd_sec.wt50>div {
    width: 50% !important;
    padding: 0px;
}

.dd_sec div.btblock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.dd_sec div.btblock>span {
    display: block;
    width: 50%;
}

.dd_sec>div.wt50>div>div {
    width: 100%;
}

.dd_sec div.btblock>span.bold {
    font-weight: 600;
    font-size: 1.3rem;
    cursor: pointer !important;
    color: #FFF;
    margin-right: 0;
}

.dd_sec:not(.wt50) div.btblock>span:not(.bold) {
    font-size: 0.9rem;
}

.dd_sec>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dd_sec>div>span {
    width: 50%;
}

.dd_sec>div>span.bold {
    font-weight: 600;
    font-size: 1.3rem;
    cursor: pointer;
    margin-right: 0;
}

.dd_sec>div .popup_ss {
    display: flex;
    position: relative;
    width: 100%;
    cursor: pointer;
    justify-content: center;
}

.dd_sec>div .popup_ss>.hh_sec {
    transform: translate(0%, 100%) !important;
    margin-bottom: -40px;
    position: absolute;
    height: fit-content;
    bottom: calc(100% + 10px);
    min-width: auto;
    max-width: 250px;
    font-size: 10px;
    margin-top: -40px;
    padding: 6px;
    color: #666666;
    background-color: #EEEEEE;
    border-radius: 8px;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgb(0 0 0 / 50%);
    display: none;
    transition: all .8s ease-in-out;
    animation-duration: 0.6s;
    animation-name: fadeIn;
    font-weight: 600;
}

.dd_sec>div .popup_ss:hover>.hh_sec {
    display: block;
}

.dd_sec>div .popup_ss:hover>.hh_sec>i {
    position: absolute;
    bottom: -11px !important;
    left: calc(50% - 12px) !important;
    top: -11px;
    transform: rotate(0deg) !important;
    width: 24px;
    height: 12px;
    overflow: hidden;
}

.dd_sec>div .popup_ss:hover>.hh_sec>i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #EEEEEE;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.dd_sec>div .popup_ss:hover>.hh_sec>div {
    border-bottom: 1px solid #888;
    color: #ff7200;
    font-size: 11px;
}

.dd_sec>div .popup_ss:hover>.hh_sec>p {
    font-size: 10px;
    line-height: normal;
    font-weight: 200;
    text-align: start;
    margin: 2px 0;
}

.dd_sec>div .popup_ss:hover>.hh_sec>p>b {
    font-weight: 600;
}

.dd_sec>div>.popup_ss span.bold {
    font-size: 12px;
}

.dd_sec>div>.popup_ss span.bold>*,
.dd_sec>div>.popup_ss span.bold {
    font-weight: 600 !important;
    font-size: 12px;
    cursor: pointer;
}

.dd_sec>div>div {
    padding: 2px;
    border-bottom: 1px solid #888;
    cursor: pointer;
}

.dd_sec>div>div:last-child {
    border-bottom: none;
}

.dd_sec.green {
    background: #228b22;
    color: #fff;
    margin: 0;
    border-color: #fff;
}

.dd_sec.green>div,
.dd_sec.green>div>div {
    border-color: #fff;
}

.dd_sec.yellow {
    background: #FFD300;
    color: #fff;
    margin: 0;
    border-color: #fff;
}

.dd_sec>div>div.yellow {
    background: #FFD300;
}

.dd_sec.yellow>div,
.dd_sec.yellow>div>div {
    border-color: #fff;
}

.dd_sec.purple {
    background: #800080;
    color: #fff;
    margin: 0;
    border-color: #fff;
}

.dd_sec>div>div.purple {
    background: #800080;
}

.dd_sec.purple>div,
.dd_sec.purple>div>div {
    border-color: #fff;
}

.dd_sec.grey {
    background: #888888;
    color: #fff;
    margin: 0;
    border-color: #fff;
}

.dd_sec>div>div.grey {
    background: #888888;
}

.dd_sec>div>div.green {
    background: #228b22;
}

.dd_sec.grey>div,
.dd_sec.grey>div>div {
    border-color: #fff;
}

.dd_sec.orange {
    background: #ff7200;
    color: #fff;
    margin: 0;
    border-color: #fff;
}

.dd_sec.orange>div,
.dd_sec.orange>div>div {
    border-color: #fff;
}

.no_cont {
    font-weight: 600;
    color: #808080;
    font-size: 16px;
}

/*#endregion Trip Analysis CSS*/

/*Ananlysis CSS*/

.ddtxt {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ddtxt>i {
    transform: rotateY(180deg);
    font-size: 1.4rem;
    margin-right: 5px;
}

.analysis_flex1 {
    width: 100%;
    background: white;
    border-radius: 5px;
    height: 13rem;
}

.analysis_item1 {
    width: 100%;
    height: auto;
    padding: 15px 10px;
    position: relative;
}

.analysis_item1>h3 {
    font-size: 1.3rem;
    font-weight: 600;
}

.analysis_item2 {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 5px;
    flex-wrap: wrap;
}

.analysis_item2>div {
    background: #E5E5E5;
    width: calc(33.33% - 10px);
    padding: 10px 5px 10px 5px;
    border-radius: 5px;
    margin: 0 5px 5px 5px;
}

.adc22 {
    /* height: 50px; */
    width: 100%;
    position: relative;


}

.adc22 .joining-line {
    position: absolute;
    height: 20px;
    border-right-color: #888;
    border-right-width: 2px;
    border-right-style: dotted;
    top: 12px;
    left: 7px;

}

.adc22>h5::before {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid #888;
    position: absolute;
    content: ' ';
    left: -18px;
    top: 0px;
}

.adc22 h5 {
    margin-left: 20px;
    font-size: 0.8rem;
    position: relative;
    min-height: 30px;
    margin-bottom: 0;
}

.adc22 h5>span {
    font-size: 0.9rem;
    display: block;
}

.adc22 h5>p {
    width: 80%;
    font-size: 0.8rem;
    line-height: unset;
    margin-bottom: 0;
    color: #4a4646;
    font-family: GOTHIC;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
}

.pp1>div {
    padding: 10px;
    border: 1px solid #FF7200;
}

.pp1>div.no_brd_rht {
    border-right: none;
}

.ls10 {
    letter-spacing: 10px;
}

.ntc {
    bottom: 15px;
    left: 10%;
    position: absolute;
    height: 20px;
    width: 20px;
    border-left: 1px solid #FF7200;
    border-bottom: 1px solid #FF7200;
    background: #FFF;
    transform: rotate(-45deg);
}

.analysis_dotted {
    display: flex;
    padding: 10px;
    text-align: center;
    font-size: 1rem;
}

.analysis_dotted :nth-of-type(1) {
    background-color: #fcc02d;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(2) {
    background-color: #ff7200;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(3) {
    background-color: #9026a7;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(4) {
    background-color: #228622;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 5px 10px 0px 10px;
}

.analysis_icon {
    text-align: center;
    color: #228622;
    font-weight: bold;
    font-size: 15px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.analysis_icon i.glyph-icon {
    font-weight: 600;
    font-size: 1.2rem;
    margin-right: 5px;
}

.analysis_item {
    width: 100%;
    height: 25px;
    background: #ff7200;
    position: relative;
    display: flex;
    border-radius: 5px;
    margin-top: 40px;
}

.analysis_item>div {
    cursor: pointer;
    position: relative;
}

.analysis_item>div:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.analysis_item>div:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.analysis_item>div>div.popov {
    display: flex;
    position: relative;
    width: 100%;
    cursor: pointer;
    justify-content: center;
}

.analysis_item>div>div.popov>div.phub {
    z-index: 1;
    min-width: 150px;
    text-align: center;
    background-color: #ebe4e4;
    border-radius: 5px;
    font-size: 12px;
    display: none;
    position: absolute;
    bottom: 5px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.analysis_item>div:hover>div.popov>div.phub {
    display: block;
}

.analysis_item>div>div.popov.lft_algn>div.phub {
    left: 0;
}

.analysis_item>div>div.popov.lft_algn>div.phub {
    left: 0;
}

.analysis_item>div>div.popov>div.phub .ppart {
    border-bottom: 1px solid #ff7200;
    font-weight: bold;
}

.analysis_item>div>div.popov>div.phub .ppart span {
    color: #ff7200;
    background-color: #ebe4e4 !important;
    font-weight: bold;
    font-size: 12px !important;
}

.analysis_item>div>div.popov>div.phub .content {
    padding: 5px;
    font-size: 11px;
    word-wrap: break-word;
}

.analysis_item>div>div.popov>div.phub .content>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.analysis_item>div>div.popov>div.phub .content>div>div.dot-icon {
    height: 2px;
    width: 10px;
    position: relative;
    background: #3e3e3e;
    margin: 5px 10px;
}

.analysis_item>div>div.popov>div.phub .content>div>div.dot-icon::before,
.analysis_item>div>div.popov>div.phub .content>div>div.dot-icon::after {
    content: " ";
    height: 8px;
    width: 8px;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -3px;
    border: 1px solid #3e3e3e;
    outline: none;
}

.analysis_item>div>div.popov>div.phub .content>div>div.dot-icon::before {
    left: -8px;
}

.analysis_item>div>div.popov>div.phub .content>div>div.dot-icon::after {
    right: -8px;
}

.analysis_item>div>div.popov>div.phub .lpart {
    background: #ff7200;
    text-align: center;
    font-size: 10px;
    color: #fff;
}

.analysis_item>div>div.popov>div.phub>i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #ff7200;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    z-index: -1;
    bottom: 0;
}

.analysis_item>div>div.popov.lft_algn>div.phub>i::after {
    left: 10%;
}

.analysis_item>div.yellow,
.analysis_item>div.yellow>div.popov>div.phub .lpart {
    background: #FFD300;
}

.analysis_item>div.yellow>div.popov>div.phub>i::after {
    background-color: #FFD300;
}

.analysis_item>div.yellow>div.popov>div.phub .ppart {
    border-bottom: 1px solid #FFD300;
}

.analysis_item>div.yellow>div.popov>div.phub .ppart span {
    color: #FFD300;
}

.analysis_item>div.green,
.analysis_item>div.green>div.popov>div.phub .lpart {
    background: #228B22;
}

.analysis_item>div.green>div.popov>div.phub>i::after {
    background-color: #228B22;
}

.analysis_item>div.green>div.popov>div.phub .ppart {
    border-bottom: 1px solid #228B22;
}

.analysis_item>div.green>div.popov>div.phub .ppart span {
    color: #228B22;
}

.analysis_item>div.purple,
.analysis_item>div.purple>div.popov>div.phub .lpart {
    background: #800080;
}

.analysis_item>div.purple>div.popov>div.phub>i::after {
    background-color: #800080;
}

.analysis_item>div.purple>div.popov>div.phub .ppart {
    border-bottom: 1px solid #800080;
}

.analysis_item>div.purple>div.popov>div.phub .ppart span {
    color: #800080;
}

.analysis_item>div.orange,
.analysis_item>div.orange>div.popov>div.phub .lpart {
    background: #FF7200;
}

.analysis_item>div.orange>div.popov>div.phub>i::after {
    background-color: #FF7200;
}

.analysis_item>div.orange>div.popov>div.phub .ppart {
    border-bottom: 1px solid #FF7200;
}

.analysis_item>div.orange>div.popov>div.phub .ppart span {
    color: #FF7200;
}

.analysis_item>div.orange+div.orange::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #727376;
}

/*Analysis CSS Clse*/

/* #region popover CSS*/
.popdv {
    display: inline-block;
    position: relative;
}

.popdv .btmplc {
    min-width: 300px;
    top: 30px;
    left: 18%;
    transform: translate(-90%, 0);
    padding: 6px;
    color: #666666;
    background-color: #EEEEEE;
    font-weight: normal;
    font-size: 13px;
    border-radius: 2px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    display: none;
    transition: all .8s ease-in-out;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
}

.popdv .btmplc.top {
    transform: translate(-87%, -105%) !important;
    top: 0 !important;
    left: 0 !important;
}

.popdv .btmplc.top>i {
    bottom: -11px !important;
    left: 92% !important;
    transform: rotate(180deg) !important;
}

.popdv:hover .btmplc {
    display: block;
}

.popdv .btmplc>i {
    position: absolute;
    bottom: 100%;
    left: 90%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
}

.popdv .btmplc>i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #EEEEEE;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

/* #endregion popover CSS*/

/* #region Tracking UI*/
.contentmo {
    font-size: 13px;
    vertical-align: bottom;
    color: #ff7200;
    color: #8f8f8f;
    border: 1px solid #ff7200;
    border-radius: 20px 20px 0 0;
    padding-bottom: 5px;
}

.tt-title {
    text-align: center;
    border-bottom: 1px solid #ff7200;
    padding: 4px;
    font-size: 15px;
    line-height: unset;
}

.tt-title>label {
    color: #ff7200;
    margin-bottom: 0;
}

table.tt-cust {
    margin: 0px !important;
    padding: 0px !important;
    border-collapse: collapse;
    width: 100%;
}

table.tt-cust tr:nth-last-child(2) td .circle:before {
    border: 1px solid green;
}

table.tt-cust,
table.tt-cust tr,
table.tt-cust td {
    border: none;
}

table.tt-cust td {
    height: 80px;
    text-align: left;
    vertical-align: top;
    padding: 5px 0 !important;
    text-transform: none;
    position: relative;
    font-size: 11px;
}

table.tt-cust span {
    display: block;
    font-size: 10px;
    text-align: right
}

.scrollmo {
    max-height: 350px;
    overflow: auto;
}

.scrollmo::-webkit-scrollbar {
    width: 5px;
    padding: 20px;
}

.scrollmo::-webkit-scrollbar-track {
    width: 5px;
    background-color: #f5f5f5;
    box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollmo::-webkit-scrollbar-thumb {
    background-color: #ff7200;
    width: 5px;
    box-shadow: inset 0 0 0 6px rgba(0, 0, 0, .1);
}

#pl {
    padding-left: 15px !important;
}

table.tt-cust strong {
    color: rgb(37, 37, 37);
    font-weight: 600;
}

.greenmm td {
    color: green !important;
}

.greenmm strong {
    color: green !important;
}

.bt td {
    padding-top: 20px !important;
}

.circle {
    position: relative;
    border: none;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    line-height: 5px;
    margin-top: 0px;
    background-color: white;
    border: 3px solid #3e3e3e;
    z-index: 2;
    margin-left: 25px;
}

.circle:first-child {
    margin-top: 0;
}

div.midd::before {
    position: absolute;
    border: 1px solid #3e3e3e;
    width: 0;
    height: calc(100% - 25px);
    display: block;
    content: '';
    left: 50%;
    z-index: 1;
    top: 25px;
}

.greenmm .circle {
    border: 3px solid green;
}

.greenmm div.midd::before {
    display: none;
}

/* #endregion Close Tracking UI*/

/* #region Address Book UI*/
.book_content>.book_content_item {
    background: #fff;
    border-left: 3px solid #FF7200;
    padding: 5px 30px;
}

.book_content>.book_content_item table.book_tbl {
    width: 100%;
}

.book_content>.book_content_item table.book_tbl td {
    display: inline-block;
    position: relative;
    justify-content: space-between;
    vertical-align: top;
    padding: 10px 15px;
    width: 20%;
}

.book_content>.book_content_item table.book_tbl td.wd25 {
    width: 20%;
}

.book_content>.book_content_item table.book_tbl td.actbt {
    width: 10%;
    height: 50%;
    padding: 0;
    position: absolute;
    right: 20px;
    transform: translateY(-50%);
    top: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.8rem;
}

.book_content>.book_content_item table.book_tbl span.tbl_tt {
    display: block;
    margin-right: 0;
    color: #727376;
    position: relative;
    font-weight: 600;
    font-size: 0.7rem;
}

.book_content>.book_content_item table.book_tbl span.tbl_tt>i {
    position: absolute;
    left: -20px;
    color: #FF7200;
    font-size: 1.3rem;
}

.book_content>.book_content_item table.book_tbl span.tbl_tt>img {
    position: absolute;
    left: -35px;
    top: -2px;
    height: 22px;
    width: 22px;
}

.book_content>.book_content_item table.book_tbl span.tbl_tt>i.ml30,
.book_content>.book_content_item table.book_tbl span.tbl_tt>img.ml30 {
    left: -30px;
}

.book_content>.book_content_item table.book_tbl span.tbl_txt {
    display: block;
    margin-right: 0;
    color: #A3A3A3;
    font-family: GOTHIC;
    font-size: 0.8rem;
}

.book_content>.book_content_item table.book_tbl span.tbl_txt_sb {
    color: #A3A3A3;
    margin-right: 0;
    font-weight: 100;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0px;
    width: 100%;
    background-color: #FFF;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.button.wd06 {
    width: 7%;
}

.button.wd10 {
    width: 10%;
}

.button.wd15 {
    width: 15%;
}

.button.wd20 {
    width: 20% !important;
}

.index {
    width: auto !important;
    z-index: 10;
}

.button.wd40 {
    width: 80%;
}

.button.bg-orange-out {
    border: 1px solid #FF7200;
    color: #FF7200;
}

.button.bg-success-out {
    border: 1px solid #228B22;
    color: #228B22;
}

.button.bg-danger-out {
    border: 1px solid #D60606;
    color: #D60606;
}

.button.bg-grey-out {
    border: 1px solid #727376;
    color: #727376;
}

.button.bg-orange {
    background-color: #007BC9;
    color: #FFF;
    border: 1px solid #007BC9;
}

.button.bg-success {
    background-color: #228B22;
    color: #FFF;
    border: 1px solid #228B22;
}

.button.bg-danger {
    background-color: #D60606;
    color: #FFF;
    border: 1px solid #D60606;
}

.button.bg-grey {
    background-color: #727376;
    color: #FFF;
    border: 1px solid #727376;
}

.button.bg-orange-out:hover {
    color: #FFF;
    background-color: #FF7200;
}

.button.wd20:hover {
    background-color: #727376;
    color: #FFF;
    border: 1px solid #727376;
}

.button.wd15:hover {
    background-color: #ff7200;
    color: #FFF;
    border: 1px solid #ff7200;
}

.button.bg-grey-out:hover {
    color: #FFF;
    background-color: #727376;
}

.button.bg-success-out:hover {
    border-color: #FFF;
    color: #FFF;
    background-color: #228B22;
}

.button.bg-danger-out:hover {
    color: #FFF;
    background-color: #D60606;
}

.button.bti>.btn_icon {
    width: 30%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button.bti.bg-orange>.btn_icon,
.button.bti.bg-success>.btn_icon,
.button.bti.bg-danger>.btn_icon,
.button.bti.bg-grey>.btn_icon {
    border-right: 1px solid #FFF;
}

.button.bti.bg-orange-out>.btn_icon {
    border-right: 1px solid #FF7200;
}

.button.bti.bg-success-out>.btn_icon {
    border-right: 1px solid #228B22;
}

.button.bti.bg-danger-out>.btn_icon {
    border-right: 1px solid #D60606;
}

.button.bti.bg-grey-out>.btn_icon {
    border-right: 1px solid #727376;
}

.button.bti.bg-grey-out:hover>.btn_icon,
.button.bti.bg-orange-out:hover>.btn_icon,
.button.bti.bg-success-out:hover>.btn_icon,
.button.bti.bg-danger-out:hover>.btn_icon {
    border-color: #FFF;
}

.button.bti.bg-grey-out:hover>.button_icon {
    border-color: #FFF;
}

p.txtr {
    margin-bottom: 0;
    font-size: 1rem;
}

p.txtr>span {
    color: red;
}

.button>.btn_txt {
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: GOTHIC;
}

.button.bti>.btn_txt {
    width: 70%;
}

.clr {
    font-size: 1.2rem;
    color: #ff7200;
    cursor: pointer;
}

/* #endregion Address Book UI*/

/* #region Custom Input*/
input.cust-input,
select.cust-input {
    width: 100%;
    border: 1px solid silver;
    padding: 4px 8px;
    font-size: 1rem;
    color: #888;
    border-radius: 4px;
}

input.cust-input:focus,
select.cust-input:focus {
    background-color: #fff;
    outline: 0;
    border-color: #FF7200;
    box-shadow: 0 0 0 0.2rem rgb(255 114 0 / 25%);
}

input.cust-input::placeholder {
    color: #727376;
    font-size: 0.7rem;
}

/* #endregion Custom Input*/

/* #region Goods Receipt Detail */
.form-container {
    background-color: #727376;
    padding: 5px;
    width: 100%;
}

.custom-collapse {
    height: 30px;
    overflow: hidden;
    position: relative;
}

.custom-collapse.autoheight {
    height: auto;
}

.collapse-view.open {
    overflow: unset !important;
}

.custom-collapse:not(.autoheight):not(.open)>.customHead {
    border-bottom: 1px solid #FFF;
}

.custom-collapse:not(.autoheight):not(.open)>.customBdy {
    display: none;
}

.custom-collapse>.customHead {
    background: #727376;
    color: #fff;
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 1.1rem;
    font-family: GOTHIC;
    padding: 0px 5px;
    justify-content: center;
    position: relative;
}

.custom-collapse>.customBdy {
    background-color: #FFF;
    /* min-height: 1px; */
}

.custom-collapse>.customHead>.collapse_icon {
    position: absolute;
    right: 15px;
    top: 0;
    height: 30px;
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
}

.custom-collapse>.customHead>.collapse_icon:after {
    content: '\002B';
    color: white;
    font-weight: bold;
}

.custom-collapse.open>.customHead>.collapse_icon:after {
    content: "\2212";
}

.tbl_div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
}

.tbl_div>.logo_sec_1 {
    width: 30%;
    height: inherit;
    text-align: center;
}

.tbl_div>.logo_sec_1 {
    width: 30%;
    height: inherit;
    text-align: center;
}

.tbl_div>.logo_sec_1>img {
    max-width: 100%;
    max-height: 100%;
}

.tbl_div>.content_sec_1 {
    width: 70%;
    height: inherit;
    text-align: center;
}

.tbl_div>.content_sec_1>p {
    margin-bottom: 0;
}

.brd_lft {
    border-left: 1px solid #727376;
}

.brd_rht {
    border-right: 1px solid #727376;
}

.brd_tp {
    border-top: 1px solid #727376;
}

.brd_btm {
    border-bottom: 1px solid #727376;
}

.brd_none {
    border: none;
}

.flex-me {
    display: flex;
    justify-content: space-between;
}

.wdt10 {
    width: 10%;
}

.wdt20 {
    width: 20%;
}

.wdt5 {
    width: 5%;
}

.wdt25 {
    width: 25%;
}

.wdt15 {
    width: 15%;
}

.wdt30 {
    width: 30%;
}

.wdt40 {
    width: 40%;
}

.wdt45 {
    width: 45%;
}

.wdt50 {
    width: 50%;
}

.wdt55 {
    width: 55%;
}

.wdt60 {
    width: 60%;
}

.wdt70 {
    width: 70%;
}

.wdt80 {
    width: 80%;
}

.wdt90 {
    width: 90%;
}

.wdt100 {
    width: 100%;
}

.dv_flex {
    display: flex;
    position: relative;
}

.pd5>div {
    padding: 5px !important;
    position: relative;
}

.pdm5 {
    padding: 5px !important;
}

input.nfc::placeholder {
    color: #959595;
    font-size: 0.75rem;
}

input.nfc:focus,
input.nfc:focus-visible,
select.nfc:focus,
select.nfc:focus-visible {
    outline: none !important;
}

.add_btn {
    background: #FF7200;
    color: #FFF;
    padding: 2px 5px;
    cursor: pointer;
}

.check_cust {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid #ff7200;
    background: #fff;
    cursor: pointer;
}

.check_cust.active::before {
    background: #ff7200;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.check_cust.active::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.cust_dd {
    display: inline-flex;
    width: 100px;
    top: 5px;
    position: absolute;
    margin-left: 5px;
}

.cust_dd.cc {
    top: -3px;
}

.cust_dd.cc .check_cust {
    border-radius: 0;
}

.cust_dd.cc .check_cust.active::before {
    border-radius: 0;
}

.cust_dd>label {
    margin-bottom: 0;
    margin-top: 1px;
    margin-left: 5px;
}

.required {
    color: red;
}

.crsr-ptr {
    cursor: pointer !important;
}

.doc_attachment {
    position: absolute;
    background: #fff;
    width: auto;
    height: 20px;
    top: calc(50% - 10px);
    z-index: 999;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #727376;
    font-size: 0.8rem;
    border-radius: 3px;
    padding: 0 5px;
}

.doc_attachment i {
    font-size: 1rem;
    font-weight: 600;
    color: #888;
}

/* #endregion Goods Receipt Detail */

/* #region Analytics CSS */
.chartContainer {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
    height: 400px;
}

.chartContainer.ppchrt {
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}

.chartContainer.ppchrt>h2 {
    position: absolute;
    top: -5px;
    left: 5px;
    font-weight: 600;
    background: #FF7200;
    color: #FFF !important;
    padding: 5px;
}

.chartContainer.ppchrt>div.indicatorDv {
    position: absolute;
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
}

.chartContainer.ppchrt>div.indicatorDv>div {
    width: 25%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chartContainer.ppchrt>div.indicatorDv>div>div {
    height: 30px;
    width: 30px;
    background-color: var(--bar-color);
}

.chartContainer.ppchrt>div.indicatorDv>div>label {
    width: calc(100% - 40px);
    margin-bottom: 0;
}

.chartContainer.ppchrt div.countdv {
    position: absolute;
    text-align: center;
    width: 100px;
    background: #727376;
    padding: 3px;
    color: #fff;
    border-radius: 5px;
    right: 30px;
    top: 30px;
}

.chartContainer>h1 {
    position: absolute;
    top: 0;
    left: 30px;
    font-size: 1.2rem;
}

.chartContainer>canvas {
    margin-top: 20px;
}

.chartCon {
    /* background: #fff; */
    height: 300px;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Line Chart CSS */
.css-chart {
    border-bottom: 2px solid #E5E5E5;
    border-left: 2px solid #E5E5E5;
    display: inline-block;
    height: var(--widget-size);
    padding: 0;
    position: relative;
    width: var(--widget-size);
}

.css-chart>.ylabel,
.grid>.ylabel {
    position: absolute;
    transform: rotate(-90deg);
    bottom: 50%;
    width: 100%;
    left: -60%;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
}

.grid>.ylabel {
    left: -54%;
    transform: rotate(90deg);
}

.css-chart>.xlabel,
.grid>.xlabel {
    position: absolute;
    bottom: -18px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 10px;
}

.grid>.xlabel {
    top: -25px;
}

.css-chart::before {
    width: 0;
    height: 0;
    border-bottom: 8px solid #E5E5E5;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: '';
    position: absolute;
    left: -6px;
    top: -6px;
}

.css-chart::after {
    width: 0;
    height: 0;
    border-left: 8px solid #E5E5E5;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    content: '';
    position: absolute;
    right: -7px;
    bottom: -6px;
}

.line-chart {
    list-style: none;
    margin: 0;
    padding: 0;
}

.data-point {
    background-color: #707070;
    border-radius: 50%;
    height: 12px;
    position: absolute;
    width: 12px;
    z-index: 1;
    cursor: pointer;
}

.data-point>.cConte {
    position: absolute;
    left: -30px;
    top: 20px;
    text-align: center;
    width: 80px;
    box-shadow: 0 4px 6px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 5px;
    font-weight: 600;
    background-color: #c7c6c6;
    display: none;
}

.data-point:hover>.cConte {
    display: block;
}

.data-point>.cConte::after {
    width: 0;
    height: 0;
    border-bottom: 10px solid #c7c6c6;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: '';
    position: absolute;
    left: 30px;
    top: -8px;
}

.line-segment {
    background-color: #FF7200;
    height: 1px;
    position: absolute;
    transform-origin: left bottom;
}

.labelsegment>ul {
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}

.labelsegment>ul>li {
    display: inline-block;
    list-style-type: none;
    width: 48px;
    text-align: left;
    position: relative;
}

.labelsegment>ul>li::before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: #E5E5E5;
    position: absolute;
    top: -5px;
    left: 12px;
}

.ulabelsegment>ul {
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}

.ulabelsegment>ul>li {
    display: inline-block;
    list-style-type: none;
    width: 48px;
    text-align: left;
    position: relative;
}

.ulabelsegment>ul>li::before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: #E5E5E5;
    position: absolute;
    top: -5px;
    left: 12px;
}

.contdv {
    position: absolute;
    right: 32px;
}

.floating_line {
    position: absolute;
    height: 0;
    width: 100%;
    border-bottom: 1px solid #888;
}

.chart-wrap {
    height: 400px;
    width: 300px;
}

.chart-wrap .title {
    font-weight: bold;
    font-size: 1.62em;
    padding: 0.5em 0 1.8em 0;
    text-align: center;
    white-space: nowrap;
}

.chart-wrap.vertical .grid {
    transform: rotate(-90deg);
}

.chart-wrap.vertical .grid .bar::after {
    transform: translateY(-50%) rotate(90deg);
    display: block;
}

.chart-wrap.vertical .grid::before,
.chart-wrap.vertical .grid::after {
    transform: translateX(-0.2em) rotate(90deg);
}

.chart-wrap .grid {
    position: relative;
    padding: 5px 0 5px 0;
    height: 100%;
    width: 100%;
    border-left: 1px solid #E5E5E5;
    border-top: 1px solid #E5E5E5;
}

.chart-wrap .grid::before {
    width: 0;
    height: 0;
    border-bottom: 8px solid #E6E6E6;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: '';
    position: absolute;
    right: -8px;
    top: -5px;
}

.chart-wrap .grid::after {
    width: 0;
    height: 0;
    border-left: 8px solid #E5E5E5;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    content: '';
    position: absolute;
    left: -2px;
    bottom: -6px;
}

.chart-wrap .bar {
    /* width: var(--bar-width); */
    height: 10px;
    margin: 20px 0;
    background-color: var(--bar-color);
    border-radius: 0 3px 3px 0;
    position: relative;
}

.chart-wrap .bar:hover {
    opacity: 0.7;
}

.chart-wrap .bar::after {
    content: attr(data-name);
    margin-left: 100%;
    padding: 10px;
    display: inline-block;
    white-space: nowrap;
}

.chart-wrap .bar::before {
    content: attr(data-lbl);
    white-space: nowrap;
    position: absolute;
    left: -35px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    top: 0;
}

.chart-wrap.vertical .grid .bar::before {
    transform: rotate(90deg);
}

.chart-wrap .bar.lbl::before {
    content: attr(data-label);
    position: absolute;
    bottom: 0;
    transform: rotate(90deg);
    left: -35px;
    width: 50px;
    font-weight: 600;
}

.chart-wrap .bar.lbl.fcc::before {
    font-size: 10px;
}

/* #endregion Analytics CSS*/

/* #region Donut chart CSS*/
.donut-chart {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.donut-chart .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.donut-chart .chart-center {
    position: absolute;
    border-radius: 50%;
}

.donut-chart .chart-center span {
    display: block;
    text-align: center;
}

.donut-chart.chart2 {
    width: 120px;
    height: 120px;
    background: #e1e1e1;
}

.donut-chart.chart2 .slice.one {
    clip: rect(0 120px 60px 0);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.donut-chart.chart2 .slice.two {
    clip: rect(0 60px 120px 0);
    -webkit-transform: rotate(327.6deg);
    transform: rotate(327.6deg);
}

.donut-chart.chart2 .chart-center {
    top: 10px;
    left: 10px;
    width: 100px;
    height: 100px;
    background: #fff;
}

.donut-chart.chart2 .chart-center span {
    font-size: 30px;
    line-height: 100px;
    color: #48b2c1;
}

.donut-chart.chart2 .chart-center span:after {
    content: "91%";
}

.lbal {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.lbal>.lbl_btn {
    width: fit-content;
    padding: 2px 10px;
    color: #FFF;
    display: inline-flex;
    font-size: 14px;
}

.lbl1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lbl1>div.tt {
    width: 60px;
    position: relative;
    color: #000;
    font-size: 12px;
    font-weight: 600;
}

.lbl1>div.tt::before {
    content: " ";
    height: 16px;
    width: 16px;
    position: absolute;
    left: -20px;
}

.lbl1>div.tt.cc1::before {
    background: #FFD300;
}

.lbl1>div.tt.cc2::before {
    background: #FF7200;
}

.lbl1>div.tt.cc3::before {
    background: #800080;
}

.lbl1>div.tt.cc4::before {
    background: #228B22;
}

.dntc::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    bottom: 6px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
}

.chart-orange .donut-chart.chart2 .slice.one,
.chart-orange .donut-chart.chart2 .slice.two,
.chart-orange .dntc::after,
.chart-orange .lbal>.lbl_btn {
    background-color: #FF7200;
}

.chart-orange .donut-chart.chart2 .chart-center span {
    color: #FF7200;
}

.chart-grey .donut-chart.chart2 .slice.one,
.chart-grey .donut-chart.chart2 .slice.two,
.chart-grey .dntc::after,
.chart-grey .lbal>.lbl_btn {
    background-color: #727376;
}

.chart-grey .donut-chart.chart2 .chart-center span {
    color: #727376;
}

.chart-blue .donut-chart.chart2 .slice.one,
.chart-blue .donut-chart.chart2 .slice.two,
.chart-blue .dntc::after,
.chart-blue .lbal>.lbl_btn {
    background-color: #225079;
}

.chart-blue .donut-chart.chart2 .chart-center span {
    color: #225079;
}

.chart-lg .donut-chart.chart2 .slice.one,
.chart-lg .donut-chart.chart2 .slice.two,
.chart-lg .dntc::after,
.chart-lg .lbal>.lbl_btn {
    background-color: #B9D874;
}

.chart-lg .donut-chart.chart2 .chart-center span {
    color: #B9D874;
}

.chart-lb .donut-chart.chart2 .slice.one,
.chart-lb .donut-chart.chart2 .slice.two,
.chart-lb .dntc::after,
.chart-lb .lbal>.lbl_btn {
    background-color: #46C1C1;
}

.chart-lb .donut-chart.chart2 .chart-center span {
    color: #46C1C1;
}

/* #endregion Donut Chart CSS*/

/* #region Pie Chart*/
@property --p {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
}

.pie {
    --p: 20;
    --b: 22px;
    --c: darkred;
    --w: 150px;

    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    margin: 5px;
    place-content: center;
    font-size: 25px;
    font-weight: bold;
}

.pie:before,
.pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
}

.pie:before {
    inset: 0;
    background:
        radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
        conic-gradient(var(--c) calc(var(--p)*1%), #0000 0);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
    inset: calc(50% - var(--b)/2);
    background: var(--c);
    transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}

.animate-chart {
    animation: p 1s .5s both;
}

.no-round:before {
    background-size: 0 0, auto;
}

.no-round:after {
    content: none;
}

@keyframes p {
    from {
        --p: 0
    }
}

.chartlb {
    position: relative;
    width: 100%;
}

.chartlb>.chartlb-item {
    position: relative;
    margin: 5px;
}

.chartlb>.chartlb-item>.chartlb-ccbox {
    --bg: #FF7200;
    background-color: var(--bg);
    height: 20px;
    width: 20px;
    position: relative;
}

.chartlb>.chartlb-item>.chartlb-txt {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    width: calc(100% - 20px);
    padding: 0 10px;
}

/* #endregion Pie Chart*/

/* #region Vehicle Detail*/
.vd_cont {
    display: flex;
    width: 100%;
    position: relative;
}

.post-15 {
    position: relative;
    top: 15px;
}

.overflowingText {
    position: absolute;
    top: -8px;
    left: -10px;
    color: #fff;
    font-size: 1rem;
    z-index: 1;
    font-weight: 700;
    padding: 3px 10px;
    background-color: #ff7200;
}

.alterSides {
    position: absolute;
    top: -7px;
    left: -7px;
    color: #fff;
    font-size: 1rem;
    z-index: 1;
    font-weight: 700;
    padding: 3px 10px;
    background-color: #727376;
}

.vd_cont *:not(.fa) {
    font-family: GOTHIC;
}

.vd_cont>.vd_cont_1 {
    width: 30%;
}

.vd_cont>.vd_cont_tbl {
    width: 50%;
    padding: 10px;
    padding-top: 19px;
    border: 1px solid #E3E3E3;
    position: relative;
}

.vd_cont>.vd_cont_1>.vd_cont_1_sec {
    padding: 10px;
    text-align: center;
}

.vd_cont>.vd_cont_1>.vd_cont_1_sec>.vd_cont_1_sec_icon {
    padding: 5px 10px;
    font-size: 2rem;
    color: #FF7200;
}

.vd_cont>.vd_cont_1>.vd_cont_1_sec>.vd_cont_1_sec_icon {
    padding: 5px 10px;
    font-size: 2rem;
    color: #FF7200;
}

.vd_cont>.vd_cont_1>.vd_cont_1_sec>.vd_cont_1_sec_tt {
    font-size: 1rem;
    color: #959595;
}

.vd_cont>.vd_cont_1>.vd_cont_1_sec>.vd_cont_1_sec_txt {
    font-size: 1.2rem;
    font-weight: 600;
    color: #707070;
}

.vd_cont>.vd_cont_2,
.vd_cont>.vd_cont_3 {
    width: 35%;
    padding: 10px;
    border: 2px solid #E3E3E3;
    position: relative;
}

.vd_cont>.vd_cont_2,
.vd_cont>.vd_cont_tbl.brtd {
    border-right: none;
}

.vd_cont>.vd_cont_2::after,
.vd_cont>.vd_cont_tbl.brtd::after {
    content: "";
    position: absolute;
    right: -1px;
    width: 0;
    top: 10px;
    height: calc(100% - 20px);
    border: 1px dashed #E3E3E3;
}

.vd_cont>.vd_cont_3,
.vd_cont>.no_btr {
    border-left: none;
}

.vd_cont>.vd_cont_2>.vd_cont_2_sec,
.vd_cont>.vd_cont_tbl>.vd_cont_tbl_sec {
    width: 100%;
    display: flex;
    padding: 5px;
}

.vd_cont>.vd_cont_2>.vd_cont_2_sec+.vd_cont_2_sec,
.vd_cont>.vd_cont_tbl>.vd_cont_tbl_sec+.vd_cont_tbl_sec {
    border-top: 1px solid #E3E3E3;
    margin-top: 5px;
}

.vd_cont>.vd_cont_2>.vd_cont_2_sec>.vd_cont_2_sec_1,
.vd_cont>.vd_cont_tbl>.vd_cont_tbl_sec>.vd_cont_tbl_sec_1 {
    font-size: 1rem;
    color: #000;
    width: 50%;
}

.vd_cont_tbl_sec_1_new {
    font-size: 0.9rem;
    display: flex;
    align-items: center !important;
    justify-content: flex-start !important;
    color: #959595;
    width: 40%;
}

.vd_cont_tbl_sec_1 {
    font-size: 0.85rem !important;
    display: flex;
    align-items: center !important;
    justify-content: flex-start !important;
    color: #959595;
    width: 40%;
    font-weight: 700;
}

.vd_cont>.vd_cont_2>.vd_cont_2_sec>.vd_cont_2_sec_2,
.vd_cont>.vd_cont_tbl>.vd_cont_tbl_sec>.vd_cont_tbl_sec_2 {
    font-size: 1rem;
    color: #FF7200;
    width: 60%;
    font-weight: 500;
    text-align: end;
    font-weight: 600;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec {
    position: relative;
    border: 1px solid #E3E3E3;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_header {
    background: #FF7200;
    color: #FFF;
    padding: 8px;
    font-size: 1rem;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_bdy {
    padding: 8px;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_bdy>.btn_cont {
    width: 50%;
    margin: 10px 25% 10px 25%;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_bdy>.file_preview>.file_preview_cont {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_bdy>.file_preview>.file_preview_cont>.preview_itm {
    width: calc(25% - 10px);
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 5px;
    border: 1px solid #E3E3E3;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_bdy>.file_preview {
    padding: 10px;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_bdy>.file_preview>p {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_bdy>.file_preview>.file_preview_cont>.preview_itm>img {
    max-width: 100%;
    max-height: 100%;
}

.vd_cont>.vd_cont_3>.vd_cont_3_sec>.vd_cont_3_sec_bdy>.file_preview>.file_preview_cont>.preview_itm>.closeb {
    position: absolute;
    top: -8px;
    right: -8px;
    background: #E3E3E3;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.fullscreen {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 1px solid #888;
    position: absolute;
    z-index: 9999;
    bottom: 2px;
    right: 2px;
    display: grid;
    place-content: center;
    cursor: pointer;
}

.fullscreen>i {
    font-weight: 600;
    color: #888;
}

/* #endregion Vehicle Detail*/
.form-control {
    padding: 10px !important;
    border-radius: 5px !important;
    font-size: 0.7rem !important;
}

.form-control::placeholder {
    font-size: 0.75rem;
}

.placeColor::placeholder {
    font-size: 0.75rem;
    color: #ff7200;
    font-weight: 700;
}

.set-w {
    width: 60%;
}

.data-cont>.data-cont-item>.data_item_content>div.btn_conte {
    width: 100%;
    background: #FFF;
    margin: 2px 1px;
    text-align: center;
    height: auto;
}


.data-cont>.data-cont-item>.data_item_content>div i.copyIcon {
    cursor: pointer;
    font-weight: 600;
    color: #888;
}

.data-cont>.data-cont-item>.data_item_content>div>span {
    display: block;
}

.data-cont>.data-cont-item>.data_item_content>div>span:nth-child(1) {
    font-size: 0.6rem;
    color: #888;
    font-weight: 600;
}

.data-cont>.data-cont-item>.data_item_content>div>span:nth-child(2) {
    font-size: 0.7rem;
    font-weight: 600;
    color: #000;
}

.data-cont>.data-cont-item>.data_item_content>div>span:nth-child(3) {
    font-size: 0.65rem;
}

.data-cont>.data-cont-item>.data_item_content>div>span.flex_box_1 {
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-inline-box;
    overflow: hidden;
    width: 100%;
    margin-bottom: -5px;
}

.data-cont>.data-cont-item>.data_item_content>div>span.flex_box_2 {
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-inline-box;
    overflow: hidden;
    width: 100%;
    margin-bottom: -5px;
}

.data-cont>.data-cont-item>.data_item_content.clps {
    display: none;
    /* animation-duration: 0.3s;
    animation-name: slideDown; */
}

.data-cont>.data-cont-item.open>.data_item_content.clps {
    display: flex;
}

.data-cont>.data-cont-item>div.collps_icon {
    position: absolute;
    display: flex;
    height: 20px;
    width: 40px;
    background: #727376;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    right: 4px;
    bottom: 3px;
    cursor: pointer;
    border-top-left-radius: 2px;
}

.data-cont>.data-cont-item>div.collps_icon>i {
    font-size: 1.5rem;
    color: #FFF;
    margin-top: -4px;
}

.data-cont>.data-cont-item.open>div.collps_icon>i {
    margin-top: 0;
    margin-bottom: -4px;
    opacity: 0;
    animation-duration: 0.3s;
    animation-name: rotateAnimation;
    animation-fill-mode: forwards;
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes rotateAnimation {
    0% {
        opacity: 0;
        transform: rotate(0);
    }

    100% {
        opacity: 1;
        transform: rotate(180deg);
    }
}

.headerpanel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #FFF;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
}

.headerpanel .logopanel {
    position: relative;
    left: 0px;
    width: 260px;
    height: inherit;
    background-color: #FFF;
    display: flex;
    align-items: center;
}

.headerpanel .logopanel>h2 {
    margin-top: 10px;
}

.headerpanel .logopanel img {
    width: 150px;
    height: 48px;
    margin-left: 15px;
}
.menutoggle {
    position: relative;
    width: 64px;
    height: 64px;
    font-size: 21px;
    margin-left: 10px;
    cursor: pointer;
    color: #778395;
    transition: all 0.2s;
}

.menutoggle i {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -11px;
    margin-left: -10px;
}
.menutoggle:hover{
    color: #007BC9;
}
.headerbar{
    display: flex;
    align-items: center ! important;
}
.header-right {
    float: right;
    width: auto;
    padding-right: 20px;
    height: 64px;
}

.headermenu {
    list-style: none;
    margin: 0;
    padding: 0;
    height: inherit;
}

.headermenu>li {
    display: inline-block;
    position: relative;
    height: inherit;
}

.headermenu>li>.btn-group {
    height: 64px;
    display: grid;
    place-content: center;
    /* min-width: 120px; */
}
.myBars{
    display: none;
}
.headermenu>li>.btn-group>.btn-logged {
    background: transparent;
    border: none;
    color: #727376;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headermenu>li>.btn-group>.btn-logged div.udeta {
    padding: 0 5px;
}

.headermenu>li>.btn-group>.btn-logged div.udeta>span {
    display: block;
    text-align: center;
}

.headermenu>li>.btn-group>.btn-logged div.udeta>span.n1 {
    font-size: 1rem;
    color: #007BC9;
    margin-bottom: -5px;
}

.headermenu>li>.btn-group>.btn-logged div.udeta>span.n2 {
    font-size: 0.8rem;
    color: #000;
}

.headermenu>li>.btn-group>.btn-logged div.udeta>span.n3 {
    font-size: 0.6rem;
    color: #727376;
}

.headermenu>li>.btn-group>.btn-logged:focus,
.headermenu>li>.btn-group>.btn-logged:active {
    outline: none !important;
    border: none !important;
    box-shadow: none;
}

.headermenu>li>.btn-group>.btn-logged img {
    vertical-align: middle;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50px;
}

.footer::before {
    content: '';
    width: 100%;
    height: 10px;
    position: absolute;
    background: #143a4d;
    top: -10px;
}

.footer>p::before {
    content: '';
    height: 10px;
}

.footer>p {
    color: #fff;
    margin-bottom: 0rem;
}

.footer {
    background: #061f2b;
    position: relative;
    margin-top: 10px;
    height: 40px;
    display: grid;
    place-content: center;
}

.caret {
    cursor: pointer;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
    /* border-top: 4px dashed; */
    border-top: 10px solid #007BC9;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

.main-content {
    height: 100%;
    font-family: GOTHIC;
    font-size: 12px;
    line-height: 1.42857143;
    color: #696c74;
    background-color: #dde0e4;
}

.sidebar {
    background-color: #FFF;
    width: 180px;
    position: fixed;
    top: 64px;
    bottom: 0px;
    left: -180px;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.4s;
}

.sidebar.open {
    left: 0;
    margin-top: 5px;
}

.nav-quirk>li.nav-parent>a:after {
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 16px;
    font-weight: 400;
    color: #bdc3d1;
    position: absolute;
    top: 10px;
    right: 15px;
}

.leftpanel-profile {
    padding: 15px;
    background-color: #3b4354;
    margin-top: 0;
}

.media,
.media-body {
    zoom: 1;
    overflow: hidden;
}

.media-left,
.media-right,
.media-body {
    display: table-cell;
    vertical-align: top;
}

.leftpanel-profile .media-object {
    width: 48px;
    height: 48px;
    padding: 3px;
    border: 2px solid #657390;
}

.leftpanel-profile .media-left {
    padding-right: 15px;
}

.media-object {
    display: block;
}

.media-body {
    width: 9992px;
}

.img-circle {
    border-radius: 50%;
}

img {
    vertical-align: middle;
}

.leftpanel-profile .media-body {
    color: rgba(255, 255, 255, 0.5);
}

.leftpanel-profile .media-heading {
    margin: 7px 0 2px;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px;
    position: relative;
}

.leftpanel-profile .media-heading>.caret {
    margin-top: 8px;
}

.btn-group.open>.user-dropmenu,
.media.open>.user-dropmenu {
    display: block;
}

.user-dropmenu {
    z-index: 99;
    display: none;
    width: 200px;
    top: 50px;
    right: 15px;
    position: absolute;
    border-color: rgba(38, 43, 54, 0.1);
    border-top: 0;
    margin-top: 0;
    margin-right: -8px;
    padding: 0px;
    background-color: #EAEAEA;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    transition: all 0.2s ease-out 0s;
}

.user-dropmenu::before {
    border-bottom: 8px solid #EAEAEA;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: "";
    display: inline-block;
    right: 5px;
    position: absolute;
    top: -7px;
}

.user-dropmenu>ul {
    list-style: none;
    padding: 5px;
    margin: 4px;
    background-color: #FFF;
}

.user-dropmenu>ul>li {
    font-size: 15px;
    font-weight: 600;
    color: #007BC9;
    cursor: pointer;
    padding: 5px;
    transition: all 0.2s;
}

.user-dropmenu>ul>li:hover {
    background-color: #FFE000;
    color: #007BC9;
}

.user-dropmenu>ul>li>i {
    margin-right: 5px;
}

.main-content>.content-body {
    padding-top: 64px;
    margin-left: 0px;
    transition: all 0.4s;
    min-height: 96vh;
}

.main-content>.content-body>.contentpanel {
    margin: 8px 15px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    background: #F8F8F8;
    padding: 10px;
    min-height: calc(100vh - 160px);
    /* min-height: 78vh; */
    /* height: 90%; */
    transition: all 0.4s;
}

.sidebar.open+.content-body {
    margin-left: 180px;
}

.tab-content {
    height: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.tab-content>.tab-pane {
    background-color: transparent;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    display: block;
}

.sidebar-title {
    text-transform: uppercase;
    font-size: 11px;
    color: #9fa8bc;
    line-height: normal;
    letter-spacing: .5px;
    padding-left: 18px;
    font-weight: 600;
    margin: 10px 0;
}

.menu-nav {
    margin: 0 0 10px;
    background-color: #fff;
    list-style: none;
    padding: 0;
    position: relative;
}

.menu-nav>li {
    border-top: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
    text-transform: uppercase;
    font-family: GOTHIC;
    border-top-color: transparent;
    position: relative;
}

.menu-nav>li.active {
    border-top-color: transparent;
    /* background-color: #FF7200; */
    background-color: #FFE000;
}

.menu-nav>li+li {
    margin-top: 2px;
}

.menu-nav>li>a {
    color: #505b72;
    padding: 10px 5px;
    border-radius: 0;
    font-weight: 700;
    transition: all 0.2s ease-out 0s;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    align-items: center;
}

.menu-nav>li.active>a>i {
    color: #fff;
}

.menu-nav>li>a>i {
    width: 16px;
    font-size: 14px;
    text-align: center;
    margin-right: 7px;
    color: #259dab;
    vertical-align: top;
    margin-top: 1.5555px;
    transition: all 0.2s ease-out 0s;
}

.menu-nav>li>a .badge {
    background-color: transparent;
    color: #9fa8bc;
    font-size: 12px;
    text-transform: none;
    padding: 0;
    font-weight: normal;
    margin-top: 4px;
    margin-right: 2px;
}

.menu-nav>li>a>img.sicon {
    height: 25px;
    width: 35%;
    padding: 0 5px;
}

.menu-nav>li>a>img.siconUpdated {
    height: 26px;
    width: 23%;
    padding: 0 5px;
}

.menu-nav>li>a>img.smallIcon {
    height: 30px;
    width: 26%;
    padding: 0 5px;
}

.menu-nav>li>a>span.txt {
    font-weight: 600 !important;
    font-size: 10px;
    display: block;
    width: 75%;
}

.menu-nav>li.active>a>span.txt {
    color: #007BC9;
}

.menu-nav>li.nav-parent>a:after {
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 16px;
    font-weight: 400;
    color: #bdc3d1;
    position: absolute;
    top: 10px;
    right: 15px;
}

.menu-nav>li.active>a:after {
    color: #007BC9;
}

.menu-nav .children {
    list-style: none;
    display: none;
    margin: 0;
    padding: 0;
    position: relative;
    border-top: 1px solid #eaecf0;
    background-color: #f6f7f8;
    transition: all 0.2s ease-out 0s;
}

.menu-nav>li.active .children {
    display: block;
    border-top: 0;
    border-bottom: 1px solid #eaecf0;
}

.no-brdr {
    border-bottom: 0.01rem solid silver !important;
}

.menu-nav>li.active .children:before {
    /* border-top: 7px solid #FF7200; */
    border-top: 7px solid #FFE000;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 0;
}

.menu-nav>li.active .no-triangle:before {
    border-top: 10px solid #FF7200;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;

    top: 0;
}

.menu-nav .children>li {
    margin-left: 21px;
    border-left: 1px solid #dbdfe6;
    font-family: GOTHIC;
    text-transform: none;
    letter-spacing: normal;
    display: block;
    font-size: 12px;
}

.menu-nav .children>li:first-child {
    padding-top: 7px;
}

.menu-nav .children>li>a {
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 5px 0 5px 18px;
    color: #657390;
    position: relative;
    height: 28px;
    transition: all 0.2s ease-out 0s;
    font-size: 12px;
    font-weight: 600;
}

.menu-nav .children>li.active>a {
    color: #007BC9;
}

.menu-nav .children>li>a:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    left: -3px;
    top: 50%;
    background-color: #9fa8bc;
    margin-top: -3px;
    border-radius: 50px;
    transition: all 0.2s ease-out 0s;
}

.menu-nav .children>li.active>a:before {
    background-color: #007BC9;
}

.menu-nav .children>li>a:after {
    content: '';
    width: 30px;
    height: 30px;
    border: 1px solid #a0a8b9;
    position: absolute;
    left: -15px;
    top: 50%;
    margin-top: -15px;
    border-radius: 50px;
    opacity: 0;
    transition: all 0.2s ease-out 0s;
}

.menu-nav .children>li.active>a:after {
    opacity: 1;
    border-color: #007BC9;
    width: 11px;
    height: 11px;
    left: -6px;
    margin-top: -6px;
}

.menu-nav .children>li>a:hover:before {
    background-color: #007BC9;
}

.menu-nav .children>li>a:hover:after {
    opacity: 1;
    border-color: #007BC9;
    width: 11px;
    height: 11px;
    left: -6px;
    margin-top: -6px;
} 

.menu-nav .children>li>a:hover {
    text-decoration: none;
    color: #007BC9;
}

.cust-breadcrumb {
    padding: 8px 26px;
    list-style: none;
    background-color: #FFF;
    border-radius: 2px;
    margin: 5px 15px;
}

.cust-breadcrumb>li {
    font-size: 12px;
    display: inline-block;
}

.cust-breadcrumb>li>a {
    color: #545b68;
    font-weight: 600;
}

.cust-breadcrumb>li+li:before {
    content: '\f105';
    font-family: 'FontAwesome';
    color: #818da7;
    margin-right: 5px;
    margin-left: 5px;
}

.cust-breadcrumb>li.active {
    color: #383838;
    font-weight: 500;
}

.cust-breadcrumb>li.active>a {
    pointer-events: none;
}

.contentpanel h1.title {
    font-size: 1.2rem;
    margin: 0;
}

.contentpanel h1.title~hr,
hr.bgg {
    margin-top: 0.1rem;
    margin-bottom: 0.5rem;
}

.cust-container {
    width: inherit;
    position: relative;
    padding: 5px 10px;
}

.cust-row {
    display: flex;
    width: 100%;
    position: relative;
    /* flex-wrap: wrap; */
}

.cust-row>.cust-col-10 {
    width: 100%;
    position: relative;
    padding: 5px 10px;
}

.ttname {
    font-size: 0.8rem;
    color: #000;
    font-weight: 600;
    display: block;
    width: 100%;
    position: absolute;
    top: -28px;
    left: 0px;
    /* border: 4px solid #727376;
    border-bottom: none; */
    padding-left: 12px;
    padding-right: 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.ttname .transportrName {
    font-size: 1.1rem;
    color: #ff7200;
    font-weight: 600;
    padding-left: 10px;
    text-decoration: underline;
    text-underline-position: under
}

.transportrNew {
    font-size: 1.1rem;
    color: #ff7200;
    font-weight: 600;

}

.cust-row>.cust-col-5 {
    width: 50%;
    position: relative;
    padding: 5px 10px;
}

.cust-row>.cust-col-2 {
    width: 20%;
    position: relative;
    padding: 5px 10px;
}

.cust-row>.cust-col-4 {
    width: 40%;
    position: relative;
    padding: 5px 10px;
}

.cust_back_div {
    width: 40%;
    position: relative;
    padding: 5px 10px;
}

.cust-row>.cust-col-4-new {
    width: 35%;
    position: relative;
    padding: 5px 10px;
}

.cust-row>.cust-col-9 {
    width: 90%;
    position: relative;
    padding: 5px 10px;
}

.cust-row>.cust-col-8 {
    width: 80%;
    position: relative;
    padding: 5px 10px;
}

.cust-row>.cust-col-7 {
    width: 70%;
    position: relative;
    padding: 5px 10px;
}

.cust-row>.cust-col-6 {
    width: 60%;
    position: relative;
    padding: 5px 10px;
}

.cust-row>.cust-col-3 {
    width: 30%;
    position: relative;
    padding: 5px 10px;
    display: flex;
}

.cust-col-3-big {
    width: 40% !important;
    position: relative;
    padding: 0px 0px;
    display: flex;
}

.custGross {
    width: 37%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custGrossSymbol {
    width: 8%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cust-row>.cust-col-1 {
    width: 10%;
    position: relative;
    padding: 5px 10px;
}

.filed-cont {
    display: flex;
    height: 45px;
    justify-content: center;
    align-items: center;
}

.filed-cont>.icon-cont {
    width: 20%;
    height: 100%;
    top: 0;
    bottom: 0;
    display: grid;
    justify-content: center;
    place-items: center;
}

.filed-cont>.icon-cont>i {
    font-size: 3rem;
}

.filed-cont>.data-cont {
    width: 80%;
    height: 100%;
    display: grid;
    align-items: center;
    padding-left: 10px;
}

.filed-cont.doc-cont>.data-cont {
    width: 80%;
    display: block;
    margin-left: 10px;
}

.filed-cont.doc-cont>.icon-cont {
    width: 10%;
    display: block;
}

.filed-cont>.data-cont>label {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: #736e6e;
    margin-bottom: 0rem;
}

.filed-cont>.data-cont>input,
.table-row>h3>input {
    width: 100%;
    font-size: 14px;
    border: 1px solid #736e6e;
    border-radius: 5px;
    padding: 2px 5px;
    color: #736e6e;
}

.table-row>h3>input {
    width: 95%;
    height: 30px;
}

.filed-cont>.data-cont>input:focus,
.table-row>h3>input:focus {
    outline: none;
    border-color: #f57421;
    color: #f57421;
}

.filed-cont>.data-cont>div,
.table-row>h3>div {
    font-size: 15px;
    color: #696c74;
    ;
}

.filed-cont>.data-cont>div>span,
.table-row>h3>div>span {
    color: #fff;
    background: #8c8e92;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}

.fa.fa_new {
    height: 45px;
    width: 45px;
    display: grid;
    justify-content: center;
    align-items: center;
    background: #696c74;
    color: #fff;
    border-radius: 50%;
    font-size: 2rem !important;
    font-family: FontAwesome;
}

.fa-char-i::before {
    content: '\0049';
}

.fa-char-g::before {
    content: '\0047';
}

.fa-char-c::before {
    content: '\0043';
}

.fa-char-p::before {
    content: '\0050';
}

.profile-title {
    font-size: 1rem;
    margin: 0;
    padding-left: 15px;
    text-transform: uppercase;
    color: #7d7d7c;
}

.image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-holder>img {
    border: 2px solid #eaeaea;
    padding: 10px;
    height: 300px;
    width: 300px;
}

.image-holder>label {
    position: absolute;
}

label.sr-file {
    color: #fff;
    background: #8c8e92;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}

.image-holder>label.sr-file {
    position: absolute;
    height: 40px;
    width: 40px;
    bottom: 35px;
    right: 68px;
}

.image-holder>label.sr-file>i {
    font-size: 1.2rem;
}

.custmr-5 {
    margin-bottom: 15px;
}

i.verified {
    color: #249c0e;
}

label.sr-file>input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.save_btn {
    float: right;
    background: #f57421;
    color: #fff;
    font-size: 15px;
    padding: 5px 10px;
    margin-top: -5px;
    border-radius: 5px;
    cursor: pointer;
}

.cust-card {
    border: none;
    background: #fff;
    border-radius: calc(.15rem - 1px);
    box-shadow: 0px 0px 5px #f57421;
    width: 100%;
    height: 250px;
}

.cust-card>.cust-card-body {
    text-align: center;
}

.cust-card>.cust-card-body>img {
    height: 100px;
    margin-top: 12%;
    user-select: none;
}

.cust-card>.cust-card-body>h3 {
    margin: 1rem 0;
    user-select: none;
    font-size: 1.5rem;
}

.cust-card>.cust-card-body>button {
    background: #f57421;
    border: #f57421;
    color: #fff;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
}

.cust-card>.cust-card-body>button:focus,
.cust-card>.cust-card-body>button:hover {
    outline: none;
    box-shadow: 2px 2px 10px #ff7200;
}

.table-row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #888;
    height: 42px;
}

.table-row>h4,
.table-row>h3 {
    font-size: 15px;
    width: 50%;
    position: relative;
    margin-bottom: 0;
}

.table-row>h4 {
    text-align: left;
}

.table-row>h3>.danger {
    color: #ea6060;
}

.table-row>h3>.verified {
    color: #47883b;
}

.table-row>h4::after {
    content: ":";
    position: absolute;
    right: 0;
}

.table-row>h3>label.sr-file {
    height: 30px;
    width: 30px;
    margin-top: 5px;
}

.pd10 {
    padding: 10px;
}

.ctct {
    margin-top: 20px;
    float: left;
}

.view_btn {
    background: #259cab;
    border: #259cab;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.view_btn:focus {
    outline: none;
}

button:disabled {
    cursor: not-allowed !important;
    opacity: 0.8;
    box-shadow: none !important;
}

.share-div {
    width: 100% !important;
}

.share-div>label {
    text-align: left;
    width: 95%;
    font-size: 15px;
    font-family: GOTHIC;
}

.custom-modal-body>.row-div>div>div.custom-group {
    display: flex;
    justify-content: center;
}

.custom-modal-body>.row-div>div>div.custom-group>.control-input {
    height: 35px;
    width: 87%;
    border-radius: 5px;
    border: 1px solid #F6AC49;
    padding: 0 5px;
    outline: none;
    caret-color: #F6AC49;
    color: #F6AC49;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-modal-body>.row-div>div>div.custom-group>.append-icon {
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e8a13f;
    color: #fff;
    cursor: pointer;
}

.doc_cont {
    text-align: initial;
    width: 95%;
    margin-left: 2.5%;
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 10px;
}

.doc_cont>div {
    width: 100px;
    background: #f57421;
    color: #fff;
    text-align: center;
    padding: 4px 0px;
    margin: 0px 10px 5px 10px;
}

.doc_cont>div>i {
    cursor: pointer;
    margin: 0 10px;
}

.msgPara {
    font-family: GOTHIC;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
}

table>tbody>tr>* {
    font-family: GOTHIC;
    font-size: 14px;
}

table>tbody>tr span {
    margin-right: 10px;
}

.btn-sm {
    padding: .1rem .5rem !important;
}

.sMsg {
    margin-left: 10px;
    font-size: 11px;
    color: #249c0e;
}

#vaccination_document {
    color: grey;
    margin-left: 8px;
    cursor: pointer;
}

.dynamicDropdown {
    width: 17%;
    height: 34px;
}

.dynamicDropdown select {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-size: 0.8rem;
    color: #495057;
    padding-left: 4px;
    border: 1px solid rgb(206, 205, 205);
}

.dynamicDropdown select:focus {
    outline: none;
}

.dynamicDropdown select>option {
    margin-bottom: 4px;
    font-size: 0.9rem;
}

.size {
    font-size: 0.8rem !important;
    height: 40px;
}

.inc_wd_30 {
    width: 26% !important;
}
.bdaOuter {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.mapView {

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.mapViewNew{
    width: 9%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.myMapBtn {
    width: 130px;
    height: 35px;
    background-color: #007BC9;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.8rem;
}

.myMapBtn:focus {
    outline: none;
    box-shadow: none;
}

.myMapBtnGrey {
    width: auto ;
    height: 35px;
    background-color: #007BC9;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 0px 10px;
    z-index: 5;
}
.myMapRght{
    margin-left: 20px;
}

.myMapBtnGrey:hover {
    padding: 2px 10px;
    transition: all 0.1s ease-in-out;
}

.myMapOrange {
    width: 20%;
    height: 33px;
    background-color: #FFE000;
    color: #007BC9;
    font-weight: 900;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.8rem;
}

.mgRight {
    margin-right: 20px;
}

button:focus {
    outline: none;
    box-shadow: none;
}


.twoCalend{
    width: 40%; 
    height: 100%;

    display: flex;
}
.firstCalenBox{
    width: 200px;
    height: 38px;
    display: flex;
    flex-direction: column;
    margin-right: 0px;
    position: relative;
}
.cashMemoBtn {
    background-color: #707070;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 12px rgba(0,0,0,.12);
    color: #fff;
    cursor: pointer;
    min-height: 24px;
    padding-left: 9px;
    padding-right: 9px;
    width: auto;
}
.deliveryMan{
    width: auto;
  
}
.calenSpan{
    position: absolute;
    top: -17px;
    color: #000;
    font-weight: 700;
    
}
.twodateInput{
    width: 100%;
    height: 100%;
    border: 1px solid silver;
    border-radius: 3px;
    padding-left: 5px;
}
.searcBox {
    display: flex;
}

.searcBoxCenter {
    width: 58%;
    display: flex;
    display: flex;
    justify-content: end;
    align-items: center;
}

.bdaSearch {
    width: 35%;
    height: 100%;
}

.myInput {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid silver;
    padding-left: 5px;
    font-size: 0.8rem;
}

.myInput::placeholder {
    padding-left: 5px;
    color: #736e6e;
    font-size: 0.7rem;
    letter-spacing: 1px;
}

.searchDiv {
    width: 21% ! important;
    height: 97%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    margin-left: 0px;
    background-color: #FFE000;
}

.transFont {
    width: auto;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    color: #000;
    padding-right: 20px;
    display: flex;
}

.customDrop {
    width: 90%;
    height: 100%;
    border: 1px solid silver;
    border-radius: 5px;
}

.searchIcon {
    font-size: 1rem;
    color: #007BC9;
}

.bdaList {
    width: 100%;
    margin-top: 20px;
}

.hideSeekText {
    display: flex;
    position: relative;
    top: 50%;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 1.2rem;
    color: red;
}

.bdaTable {
    width: 100%;
    z-index: 3;
}

.bdaTableNew {
    max-width: 100%;
    z-index: 3;
    overflow-x : scroll !important;
    overflow-y: hidden;
}

.bdaHeader {
    width: 100%;
    height: 55px;
    display: flex;
    z-index: 3;
}

.bdaH1 {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(0 0 0 / 10%);
    border-left: none;
    border-right: none;
    background-color: #ff7200;
}

.bdaH1 span {
    font-size: 0.75rem;
    font-weight: 700;
    color: #fff;
}

.bdaH2 {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(0 0 0 / 10%);
    border-right: none;
    background-color: #FFE000;
}
.generic-table-wrapper {
    overflow-x: scroll !important;
  }
  .mt20{
    margin-top: 20px;
  }
  .generic-table thead th,
  .stageDashboard-table thead th {
    color: #007BC9;
  }
  .generic-table thead th,
  .generic-table tbody td,
  .stageDashboard-table thead th,
  .stageDashboard-table tbody td {
    border: none;
    max-width: 8rem;
    word-wrap: break-word;
  }
  .generic-table thead th:last-child,
  .generic-table tbody td:last-child,
  .stageDashboard-table thead th:last-child,
  .stageDashboard-table tbody td:last-child {
    max-width: max-content;
  }
  .generic-table tbody,
  .stageDashboard-table tbody {
    background-color: #fff;
  }
  .generic-table,
  .stageDashboard-table {
    margin: 1em auto;
    text-align: center;
  }
  .generic-table,
  .stageDashboard-table {
    width: 100%;
    /* border: 1px solid red; */
  }
  
  .generic-table thead th,
  .generic-table tbody td,
  .stageDashboard-table thead th,
  .stageDashboard-table tbody td {
    border: 1px solid rgb(0 0 0 / 10%);
    font-family: 'Bai Jamjuree', sans-serif !important;
    vertical-align: inherit;
    padding: 10px;
  }

  .bg-accent {
    background-color: #FFE000;
    color: #007BC9;
  }
  .generic-table td {
    padding: 0.75rem;
    vertical-align: top;
    color:#000;
    font-size: 12px;
    font-weight: 600;
    border-top: 1px solid #dee2e6;
  }

  .generic-table tbody tr td:first-child {
    color: #007BC9;
  }
  .table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    color:#000;
    font-size: 12px;
    font-weight: 600;
    border-top: 1px solid #dee2e6;
}
table {
    border-collapse: collapse;
  }
  /* .custom-scrollbar */
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 10px;
  }
  
  .scroll.custom-scrollbar::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }



.wid3 {
    width: 4.4% !important;
    text-align: center;
}

.wid5 {
    width: 5% !important;
    text-align: center;
}

.wd10 {
    width: 10%;
    text-align: center;
}

.wd200 {
    width: 20%;
    text-align: center;
}

.wid20 {
    width: 20%;
    text-align: center;
}

.wid30 {
    width: 30%;
    text-align: center;
}
.wid50 {
    width: 50%;
    text-align: center;
}

.wid40 {
    width: 40%;
    text-align: center;
}

.wd47 {
    width: 60%;
    text-align: center;
}

.trashIcon {
    font-size: 1rem;
    cursor: pointer;
    color: red;
}

.editIcon {
    font-size: 1rem;
    cursor: pointer;
    color: green;
}

.brdrRight {
    border-right: 1px solid rgb(0 0 0 / 10%);
}

.bdaH2 span {
    font-size: 0.7rem;
    font-weight: 700;
    color: #007BC9;
    border-radius: 4px;
    padding: 5px;
}

.bdaH0 {
    width: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(0 0 0 / 10%);
    background-color: #ff7200;
    text-align: center;
}


.bdaH00 {
    /* width: 15%; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(0 0 0 / 10%);
    background-color: #FFE000;
    text-align: center;
}

.bdaH000 {
    /* width: 10%; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(0 0 0 / 10%);
    background-color: #FFE000;
    text-align: center;
}

.bdaH000>span {
    font-size: 0.75rem;
    font-weight: 700;
    color: #007BC9;
}

.bdaH00>span {
    font-size: 0.75rem;
    font-weight: 700;
    color: #007BC9;
}

.bdaH0 span {
    font-size: 0.75rem;
    font-weight: 700;
    color: #fff;
}

.bdaH0RemoveBtn {
    /* width: 40px; */
    height: 27px;
    background-color: red;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-size: 0.7vw;
}

.bdaH30 {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(0 0 0 / 10%);
    border-right: none;
    background-color: #ff7200;
}

.bdaH30 span {
    font-size: 0.85rem;
    font-weight: 700;
    color: #fff;
}

.bdaContent {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
}

.dynamicHgt {
    width: 100%;
    min-height: auto;
    /* display: flex; */
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
}

.newTripElem {
    width: 100%;
    min-height: auto;
    display: flex;
    position: relative;
}

.modifyHgt {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
}

.modifyHgtNew {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
}

.absAddress {
    color: green !important;
    position: absolute;
    top: 0px;
}

.no_top {
    border-top: none;
    background-color: #fff;
    text-align: center;
}

.noBrdr {
    border: none;
    border-left: 1px solid rgb(0 0 0 / 10%);
    display: flex;
}

.modifyCss {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 13px;
    color: #000 !important;
}

.modifyCssActive {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px dashed #000;
    border-radius: 3px;
    color: #ff7200 !important;
    background-color: rgb(0 0 0 / 4%) !important;
}

.iconCss {
    position: absolute !important;
    top: 100px !important;
}

.bdaPlane {
    color: #000;
    cursor: pointer;
    font-size: 1.2rem;
    position: absolute;
}

.bdaPlaneNew1 {
    color: #000;
    cursor: pointer;
    font-size: 1.2rem;
    position: absolute;
    top: 10px;
}

.bdaSpan {
    font-size: 0.7rem !important;
    color: #000 !important;
    font-weight: 600 !important;
}

.bdaSpangreen {
    font-size: 0.8rem !important;
    color: green !important;
    font-weight: 800 !important;
}

.bdaSpanred {
    font-size: 0.8rem !important;
    color: red !important;
    font-weight: 800 !important;
}

.specialSpan {
    color: #6495ed !important;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
}

.bdaSpanOrange {
    font-size: 0.8rem !important;
    color: #007BC9 !important;
    font-weight: 600 !important;
}

.bdaSpanRed {
    font-size: 0.8rem !important;
    color: Red !important;
    font-weight: 600 !important;
}

.bdaEye {
    font-size: 1rem;
    cursor: pointer;
    color: #ff7200;
}

.bdaViewDetail {
    width: 100%;
    height: 400px;
}

.mapOuter {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    height: 83vh;
    z-index: 2;
    position: relative;
}

#mapDimensions {
    width: 100%;
    height: 100%;
}
#mapDimensions.hide{
    display: none;
}
.map_btn_div {
    width: 4%;
    height: 30px;
    position: absolute;
    z-index: 1000000000;
    top: 55px;
    left: 10px;
    border-radius: 4px;
    background-color: #ff7200;
}

.map_btn {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    background-color: #ff7200;
}

.map_icon {
    font-size: 1rem;
}

.orangeColor {
    color: #007BC9 !important;
    font-weight: 600 !important;
}

.darkGrey {
    color: #808080 !important;
    font-weight: 600 !important;
}

.whiteBg {
    background-color: #fff !important;
}

.noData {
    width: 100%;
    height: 55vh;
    position: absolute;
    /* background-color: cyan; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.noData>p {
    font-size: 1.2rem;
    color: red;
}

.reqDistElem {
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(0 0 0 / 3%);
    border-radius: 5px;
    padding: 13px;
}

.reqDistRow {
    width: 100%;
    height: 60px;
    justify-content: space-between;
}

.wd50 {
    width: 40%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: space-between;
}

.wd20 {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.wd80 {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.selectBda {
    color: black;
    font-size: 0.9rem;
    font-weight: 700;
}

.selectDrop {
    width: 90%;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid silver;
}

.flexRow {
    display: flex;
}

.qtyInput {
    width: 90%;
    height: 65%;
    padding-left: 5px;
    border-radius: 4px;
    border: 1px solid silver;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
#firstHeading{
    color: #000;
    font-weight: 800;
    font-size: 20px;
}
#infoSpan {
    color: #000;
}

.marker-position {
    top: 26px;
    left: 0;
    background-color: #fff;
    opacity: 1;
    padding: 3px;
    border-radius: 10px;
    position: relative;
}

.pad-0 {
    padding: 0px !important;
}

.mapHeader {
    width: 98%;
    height: 70px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(0 0 0 / 0.6%);
}

.wd20 {
    width: 20%;
    height: 70px;
    padding-left: 0px;
}

.wd-100 {
    width: 90%;
}

.mapHeaderSpan {
    font-size: 1rem;
    color: #000;
    font-weight: 700;
}

.mapHeaderValue {
    font-size: 1.1rem;
    color: #ff7200;
    font-weight: 700;
    padding-left: 16px;
}

.mg-l {
    margin-left: 20px;
}

.wd50 {
    width: 46%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex40 {
    width: 40%;
    height: 40px;
    display: flex;
    align-items: center;
}

.myMapBtn {
    position: absolute;
    left: 16%;
    top: 86px;
    z-index: 111;
}

.wd60 {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.custmBtnWdth {
    width: 20% !important;
    padding-left: 15px;
    padding-right: 15px;
}

.mg-R {
    margin-right: 20px;
}

.autoWidth {
    width: auto !important;
}

.positionsElem {
    display: none;
}

.positionsElem.slide-in {
    width: 500px !important;
    min-height: auto;
    position: absolute;
    top: 60px;
    display: block;
    z-index: 1000;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 0px 20px black;
    animation: slideIn 0.5s forwards;
    -webkit-animation: slideIn 0.5s forwards;
    border: 2px solid rgb(0 0 0 / 24%);
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    0% {
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-10%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}

.outerCover {
    width: 100%;
    height: auto;
}

.postFixed {
    position: sticky;
    top: 0px;
    height: 65px;
}

.postHeader {
    width: 100%;
    height: 40px;
    display: flex;
    margin-bottom: 8px;
    border-bottom: 2px solid rgb(0 0 0 / 12%);
}

.wd90 {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.truckFa {
    position: absolute;
    left: 10px;
    color: #007BC9;
    font-size: 1.7rem;
    padding-left: 3px;
    padding-right: 15px;
}

.truckFas {
    position: relative;
    left: 10px;
    color: #ff7200;
    font-size: 1.7rem;
    padding-left: 3px;
    padding-right: 15px;
}

.postDetails {
    font-size: 1rem;
    color: #000;
    font-weight: 700;
}


.wd10 {
    width: 10% !important;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.backBtn {
    width: 8% !important;
    height: 27px !important;
}

.leftOpenArr {
    color: #000;
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;
}

.postBody {
    width: 100%;
    height: auto;
}

.routesElement {
    width: 90%;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000;
    font-weight: 700;
    background-color: rgb(0 0 0 / 2%);
    box-shadow: 1px 1px 12px silver;

}

.alignBdr {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.routesElementActive {
    width: 90%;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #007BC9;
    background-color: #FFE000;
    box-shadow: 1px 1px 18px silver;
}

.wd15 {
    width: 15%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    /* background-color: cyan; */
}

.round {
    width: 55%;
    height: 82%;
    border-radius: 50%;
    background-color: rgb(0 0 0 / 9%);
}

.greenRound {
    width: 55%;
    height: 82%;
    border-radius: 50%;
    background-color: green;
}

.redRound {
    width: 55%;
    height: 82%;
    border-radius: 50%;
    background-color: red;
}

.midRound {
    width: 55%;
    height: 82%;
    border-radius: 50%;
    background-color: rgb(0 0 0 / 9%);
}

.dashhed {
    position: absolute;
    width: 2px;
    height: 79%;
    border: 1px dashed black;
    z-index: 10;
}

.wd85 {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    position: relative;
}

.wd85>span {
    color: #505050;
    font-size: 0.65rem;
    font-weight: 600;
}

.latlngElem {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.latlngElemSpan {
    font-size: 1.1rem;
    color: #000;
    font-weight: 700;
}

.latlngInpElem {
    width: 100%;
    height: auto;
}

.latlngInpInn{
    width: 100%;
    height: 40px;
    color: #727376;
    font-weight: 700;
    /* padding: 10px !important; */
    border-radius: 5px !important;
    font-size: 0.7rem !important;
    border: 1px solid #ced4da;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
}

/* .latlngInpInn {
    width: 100%;
    height: auto;
    padding: 10px;
    padding-top: 0px;
    display: flex;
    justify-content: space-around;
    background-color: rgb(0 0 0 / 5%);
    margin-bottom: 9px;
    position: relative;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
} */
.newwidthStyle{
    width: 50%;
    flex: 1;
}
.slotElemGrand {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
}

.slotElemParent {
    width: 100%;
    display: flex;
    height: 100%;
}

.newtripSpan {
    width: 90px;
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    padding-left: 10px;
}

.pdTopp {
    margin-top: 12px;
}

.sNo {
    width: 17px;
    height: 18px;
    position: absolute;
    top: -9px;
    left: -5px;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    background-color: #ff7200;
}

.latElem {
    min-width: 200px;
    max-width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    margin-top: 12px;
}

.latElemNew {
    width: 92%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addreddBtn1 {
    width: 80px;
    height: 38px;
    background-color: green;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    font-size: 10px;
    border: none;
    margin-left: 12px;
    position: relative;
    /* top: 6px; */
}

.addreddBtn3 {
    width: 11%;
    height: 28px;
    background-color: green;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.7vw;
    border: none;
    margin-right: 10px;
}

.w50d {
    width: 50%;
}

.latElemSpan {
    font-size: 1em;
    color: #000;
    font-weight: 700;
    margin-right: 1px;
}

.latlngField {
    min-width: 68%;
    height: 33px;
    border: 1px solid silver;
    border-radius: 4px;
    padding-left: 5px;
    border: none;
}

.addressField {
    width: 50%;
    height: 40px;
    border: 1px solid silver;
    border-radius: 4px;
    padding-left: 5px;
    border: none;
}

.w30d {
    width: 66%;
    height: 33px;
    border: 1px solid silver;
    border-radius: 4px;
    padding-left: 5px;
    border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.operators {
    width: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

}

.faAdd {
    font-size: 0.8rem;
    color: green;
    cursor: pointer;
    margin-right: 5px;
}

.faMinus {
    font-size: 0.8rem;
    color: red;
    cursor: pointer;
    margin-right: 2px;
}

.createRouteBtn {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.createRouteBtn>button {
    width: auto;
    height: 30px;
    background-color: #007BC9;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
}

.header30 {
    width: 50%;
    height: 40px;
    display: flex;
}
.filterDiv{
    width: 200px;
}
.filterDiv>div{
    background-color: #fff;
}
.filterDivFlex{
    width: 350px;
    display: flex;
}
.inpPre>div{
    text-align: start;
    width: 100%;
    background-color: #fff;
}
.filterDivFlexDrop>div{
    width: 100px;
    display: flex;
}
.headerSpan {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    font-size: 0.8rem;
    color: #000;
    font-weight: 700;
}

.headerInput {
    width: 70%;
    height: 100%;
    display: flex;
}

.header10 {
    width: 10%;
    height: 100%;
}

.dateInput {
    width: 86%;
    border: 1px solid silver;
    border-radius: 3px;
    padding-left: 8px !important;
}

.multiselect {
    width: 83%;
    z-index: 10;
}

.selectBox {
    position: relative;
    height: 38px !important;
    cursor: pointer;
}

.selectBox select {
    width: 100%;
    height: 39px !important;
    border: 1px solid silver;
    border-radius: 5px;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkboxes {
    display: none;
    border: 1px #dadada solid;
    background-color: #fff !important;
}

#checkboxes label {
    height: 30px;
    display: block;
    color: black;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
}

#checkboxes label:hover {
    background-color: #ff7200;
    color: #fff;
}

#one {
    width: 17px;
    height: 17px;
    margin-bottom: -2px;
    margin-right: 15px !important;
    margin-left: 10px;
}

.ct100 {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
}

.ct1001 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.ct100New {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ct100.open {
    width: 97%;
    height: 100%;
    display: flex;
}

.ct100.close {
    display: none;
}

.addressRow.open {
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addressRow.close {
    display: none;
}
/* .latlngFieldNew{
    width: 80%;
    height: 40px;
    color: #727376;
    font-weight: 700;
    padding: 10px !important;
    border-radius: 5px !important;
    font-size: 0.7rem !important;
    border: 1px solid #ced4da;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: fixed;
} */
.latlngFieldNew {
    min-width: 83%;
    height: 33px;
    border: 1px solid silver;
    border-radius: 4px;
    padding-left: 5px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.latlngFieldNew1 {
    width: 27% !important;
    height: 33px;
    border: none !important;
    border-radius: 0px !important;
    padding-left: 5px;
    border-bottom: 1px solid #ff7200 !important;
    /* background-color: red; */
}

.addreddBtn2 {
    width: 11%;
    height: 28px;
    background-color: green;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.7vw;
    border: none;
    position: absolute;
    right: 7px;
}

.addreddBtn4 {
    width: 10%;
    height: 28px;
    background-color: green;
    position: absolute;
    right: 0px;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.7vw;
    border: none;
    margin-right: 17px;
}

.placeHolderWidth {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 100;
}

.location-search-input {
    width: 100%;
    height: 33px;
    border-radius: 4px;
    border: none;
    padding-left: 10px;
    font-size: 0.8rem;
}

.location-search-input-modal {
    position: relative;
    top: -11px;
    width: 30%;
    height: 36px;
    border: 1px solid #C8C8C8;
    border-radius: 4px;
    z-index: 1113;
    padding-left: 8px;
    font-size: 1rem;
}

.autocomplete-dropdown-container {
    display: block;
    overflow: unset !important;
    z-index: 10000 !important;
}

.addressDropElem {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.restictHgt {
    height: 150px !important;
}

.mgAuto {
    width: 95%;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
}

.addressManag {
    display: none;
}

.addressList {
    width: 100%;
    height: 100px;
    display: flex !important;
    flex-direction: column !important;
}

.listingAdd {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.ss {
    display: flex;
    flex-direction: column;
}

.rowSpan {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: rgb(0 0 0 / 4%);
    padding: 8px;
    margin-bottom: 12px;
    flex-wrap: wrap;
    /* border-left: 3px solid #727376; */
}

.rowInner {
    /* width: 47%; */
    flex: 1;
    height: 100%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.key {
    width: 200px;
    height: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.keyValue {
    color: #000;
    font-size: 0.8rem;
    font-weight: 700;
}

.value {
    width: 60%;
    height: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inputValue {
    width: 95%;
    height: 33px;
    border: 1px solid silver;
    border-radius: 2px;
    padding-left: 4px;
    background-color: #fff;
}

.alterinputValue {
    border-radius: 0px;
    border: none;
    /* border-bottom: 1px solid #ff7200; */
}

.requestBody {
    width: 100%;
    height: auto;
    padding: 10px;
}

.reqHeader {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reqHeadSpan {
    color: #000;
    margin-right: 10px;
    font-size: 0.8rem;
    font-weight: 600;
}

.select2-container {
    width: 190px !important;
    z-index: 111111;
}

.reqBody.hide {
    display: none;
}

.reqBody.open {
    width: 100%;
    height: auto;
    padding-top: 16px;
    background-color: rgb(0 0 0 / 3%);
    border-radius: 4px;
    display: block;
    border-left: 4px solid #727376;
}

.reqbod3 {
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(0 0 0 / 3%);
}

.customRow {
    width: 100%;
    height: auto;
    /* padding: 10px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.custom47 {
    min-width: 200px;
    flex: 1;
    max-width: 100%;
    padding: 10px;
    display: flex;
}

.custom100 {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 5%);
}

.custSpanVol {
    font-size: 1rem;
    color: #727376;
    font-weight: 700;
    margin-right: 20px;
}

.calVol {
    min-width: 20px;
    height: 20px;
    border-bottom: 2px solid #007BC9;
    font-size: 0.9rem;
    color: #007BC9;
    font-weight: 700;
}

.custom40 {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: flex-start;
    padding-left: 12px;
}

.alignVet {
    width: 100%;
    display: flex;
    height: 60px;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    border-radius: none !important;
    border-left: none ! important;
}

.piAbs {
    position: absolute;
    top: 3px;
    right: 6px;
    color: #727376;
    font-weight: 800;
}

.alignVet>div {
    min-width: 26%;
    /* display: flex; */
    text-align: start;
    background-color: #fff;

}

.custSpan {
    color: #000;
    font-size: 0.8em;
    font-weight: 600;
}

.custInp {
    width: 90%;
    height: 32px;
    border: none;
    background-color: transparent;
    color: #007BC9;
    font-weight: 700;
    border-bottom: 1px solid rgb(0 0 0 / 25%);
    padding-left: 5px;
}

.custInput {
    width: 100%;
    height: 38px;
    background-color: #fff;
    font-weight: 700;
    color: #727376;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 6px;
    padding-left: 5px;
}

.custInputProd {
    width: 50%;
    height: 33px;
    background-color: #fff;
    font-weight: 700;
    color: #727376;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 6px;
    padding-left: 5px;
    margin-left: 5px;
}

.toggleWidthh {
    width: 29% !important;
    margin-right: 15px;
}

.custInput::placeholder {
    color: #7d7d7c;
    font-weight: 300;
}

.custInputProd::placeholder {
    color: #7d7d7c;
    font-weight: 300;
}

.sNumber {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -5px;
    top: -6px;
    background-color: #007BC9;
    color: #fff;
}

.groupadd {
    position: absolute;
    right: 5px;
    top: -15px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    background-color: green;
    border: none;
    font-size: 0.65rem;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
}

.groupremove {
    position: absolute;
    right: 70px;
    top: -15px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    background-color: red;
    border: none;
    font-size: 0.65rem;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
}

.custom60 {
    width: 60%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
}

.custom60>div {
    width: 90%;
    background-color: #fff !important;
}


.customBtnElem {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.customBtn {
    width: auto;
    height: 29px;
    padding-left: 13px;
    padding-right: 13px;
    background-color: #007BC9;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-weight: 700;
}

.addBg {
    background-color: rgba(0, 0, 255, 0.08);
    border-radius: 4px;
}

.addLightBg {
    background-color: rgb(0 0 0 / 2.6%);
    margin-bottom: 8px;
    border-radius: 4px;
}

.brdrbg {
    border-radius: 3px;
    height: 100% !important;
    background-color: #fff;
    border: 1px solid rgb(0 0 0 / 16%);
}

.editElem {
    width: 96%;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.combineElem {
    box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
}

.trip100 {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    /* background-color: rgb(0 0 0 / 6%); */
    margin-bottom: 16px;
    position: relative;
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 2px;
    flex-wrap: wrap;
}

.trip1000 {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.tripInputNew {
    min-width: 20%;
    height: 35px;
    border-radius: 4px;
    color: #007BC9;
    font-weight: 800;
    padding-left: 5px;
    border: none;
    border: 1px solid #727376;
    margin-left: 13px;
}

.trip48 {
    min-width: 230px;
    flex: 1;
    height: 50px;
    display: flex;
    border-radius: 4px;

}

.trip40 {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.trip60 {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.tripSpan {
    font-size: 1.1em;
    font-weight: 700;
    color: #000;
    padding-left: 10px;
}

.tripSpansss {
    font-size: 0.9rem;
    font-weight: 700;
    color: #000;
    padding-left: 0px;
}

.tripInput {
    width: 90%;
    height: 30px;
    border-radius: 4px;
    padding-left: 5px;
    border: none;
}

.trip48New {
    min-width: 230px;
    flex: 1;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 4%);
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
}

.group {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    border: 1px dashed silver;
    background-color: rgb(0 0 0 / 2%);
    border-radius: 5px;
    color: #fff;
    margin-bottom: 7px;
}

.newGroup {
    background-color: #007BC9;
    border-radius: 4px;
    color: #fff;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-right: 7px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    width: auto;
}

.vehNumCon {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

.groupCross {
    position: absolute;
    top: -8px;
    font-size: 12px;
    right: -6px;
    color: #727376;
    cursor: pointer;
    width: 18px;
    height: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: red;
}

.groupCross:hover {
    box-shadow: 0px 0px 4px grey;
}

.newgroupCross {
    position: absolute;
    top: -7px;
    font-size: 14px;
    right: -5px;
    color: red;
    cursor: pointer;
}

.groupListView {
    width: 100%;
    height: auto;
    display: flex;
    /* padding: 22px; */
    border-radius: 4px;
    border: 1px dashed #808080;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: #fff;
}

.groupListViewIn {
    width: 100%;
    height: 74px;
    display: flex;
    border-radius: 4px;
    margin-bottom: 4px;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    position: relative;
    text-align: left;
}

.groupListViewInHgt {
    height: 40px;
}

.shapeTypeDiv {
    width: 51%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.shapeTypeDiv>div {
    width: 90%;
    background-color: #fff;
}

.alterWidthsNew {
    width: 100%;
    height: 50px;
    display: flex;
    border-radius: 4px;
    margin-bottom: 4px;
    display: flex;
    margin-top: 10px;

    position: relative;
    text-align: left;
}

.alterWidths {
    width: 52%;
    display: flex;
    justify-content: flex-start;
}

.alterWidths2 {
    width: 48%;
    display: flex;
    position: relative;
    justify-content: flex-start;
}

.alterWidths>div {
    width: 50% !important;
    background-color: #fff;
    position: relative;
    right: 30px;
}

.asignFlex {
    display: flex;
    flex-direction: column !important;
}

.grouplist2 {
    width: 60%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.toggleWidth {
    width: 35% !important;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.toggleWidthNew {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.groupSpan {
    color: #007BC9;
    font-size: 0.95em;
    font-weight: 700;
    padding-left: 4px;
}

.grouplist3 {
    width: 95%;
    height: 100%;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
}

.notFound {
    width: 100%;
    height: 30px;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customDropdown {
    width: 90%;
    height: 32px;
    border-radius: 4px;
    border: 1px solid silver;
}

.orSpan {
    position: absolute;
    top: -8px;
    left: 46%;
    color: red;
    font-size: 0.9rem;
    font-weight: 700;
}

.tripIcElem {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewMap {
    color: blue ! important;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
}

.tripButtons {
    width: 100%;
    height: auto;
    position: relative;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 0px;
}

.allDriver {
    width: auto;
    min-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #04AA6D;
    font-size: 0.7rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.deAll {

    width: auto;
    min-height: 26px;
    padding-left: 14px;
    padding-right: 20px;
    background-color: red;
    font-size: 0.7rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.loginToggleBtn {
    width: 75%;
    height: 50px;
    display: flex;
    justify-content: center !important;
    align-items: flex-start !important;
}

.userTypeDiv {
    width: 100%;
    height: 42px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    background-color: #fff;
}

.userTypesActive {
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #007BC9;
    transition-duration: 0.8s;
    border: 1px solid #fff;
    box-shadow: 1px 1px 28px grey;
}

.userTypeSpan {
    font-size: 0.9rem !important;
    color: #000 !important;
}

.userTypeActiveSpan {
    font-size: 0.9rem !important;
    color: #fff !important;
}

.userTypes {
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    border-left: 1px solid darkgray;
    border-right: 1px solid darkgray;
}

.userTypes:nth-child(1) {
    border: none;
}

.userTypes:nth-last-child(1) {
    border: none;
}

.startBtn {
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 8px;
}

.skipBtn {
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 8px;
}

.modal-content {
    border-radius: 10px !important;
    /* overflow: hidden; Ensures content respects the border-radius */
  }

/* pagination */

.drop-down-page-navigation {
    width: 40px;
    height: 30px;
    font-size: 14px;
    margin-left: 8px;
    background-color: inherit;
    color: var(--project-text-color);
    border: solid 1px #dee2e6;
    border-radius: 5px;
  }
  .drop-down-page-navigation:hover {
    border: solid 1px #dee2e6;
  }
  .drop-down-page-navigation:focus {
    outline: none;
  }

  .drop-down-page-navigation  option {
    text-align: center;
  }
  .drop-down-page-navigation  option:hover{
    background-color: var(--primary-color);
  }


.pagination-container .pagination {
        --bs-pagination-padding-x: 0.75rem;
        --bs-pagination-color: var(--primary-color);
        --bs-pagination-hover-color: var(--primary-color);
        --bs-pagination-focus-color: var(--primary-color);
    }
    
.pagination-container .active>.page-link, .page-link.active {
        z-index: 3;
        color: var(--bs-pagination-active-color);
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }

.paginationDiv {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.paginationBtn {
    width: auto;
    height: 29px;
    position: relative;
    top: 0px;
    background-color: #007BC9;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 12px;
}

.editTrip100 {
    width: 100%;
    height: 40px;
    justify-content: flex-start;
    align-items: center;
}

.editHead {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.EditSpan {
    font-size: 0.8rem;
    color: #000;
    font-weight: 700;
    padding-left: 10px;
}

.EditInp {
    width: 30%;
    height: 30px;
    margin-left: 15px;
    border: 1px solid silver;
    border-radius: 4px;
}

.boxDiv {
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
}

.editBodyy {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    justify-content: flex-start;
    align-items: flex-start;

}

.underFont {
    font-size: 1.1rem;
    color: #000;
    font-weight: 900;
}

.locationsList {
    width: 100%;
    height: auto;
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 13px;
    background-color: rgb(0 0 0 / 3%);
    padding-left: 10px;
    border-left: 3px solid #727376;
}

.locationsList1 {
    width: 100%;
    height: auto;
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-start;
    margin-top: 0px;
    align-items: flex-start;
    background-color: rgb(0 0 0 / 4%);
    padding-bottom: 12px;
    padding-left: 10px;
    padding-top: 13px;
    cursor: pointer;
    border-left: 3px solid #727376;
    flex-wrap: wrap;
}

.tripIcons {
    width: 7%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tripIcons1 {
    width: 4%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.locationInput {
    width: 93%;
    height: 37px;
    border: none;
    border-bottom: 2px solid silver;
    padding-left: 10px;
    margin-bottom: 9px;
    background-color: transparent;
    color: #585858;
    font-weight: 700;
    text-overflow: ellipsis;
}

.customDropDown {
    width: 93%;
    height: 33px;
    border: 1px solid silver;
    border-radius: 3px;
    outline: none;
    margin-bottom: 15px;
}

.allotedVehicle {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgb(0 0 0 / 5%);
    border-left: 3px solid #727376;
    padding-top: 12px;
    padding-bottom: 12px;
}

.vehNum {
    color: #007BC9 !important;
    padding-left: 10px;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.allotedDrop {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
}

.alterDrop {
    width: 94%;
    height: 35px;
}

.arroWElem {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.downArrow {
    font-size: 0.9rem;
    color: #736e6e;
    animation: bounce 1.5s infinite;
    position: relative;
    bottom: 6px;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-3px);
    }

    60% {
        transform: translateY(3px);
    }
}

.etaDistance {
    position: absolute;
    right: 55%;
    color: #ff7200;
    font-weight: 700;
    font-size: 0.8rem;
}

.newetaTime{
    display: flex;
    /* border: 1px solid black; */
    width: 200px;
    position: absolute;
    left: 7%;
    color: #007BC9;
    font-weight: 700;
    font-size: 0.7rem;
    justify-content: flex-end;
}

.ptaTime{
    top: 2px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0px 5px;
    width: 200px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 55%;
    color: #007BC9;
    font-weight: 700;
    font-size: 0.7rem;
}

.etaTime {
    position: absolute;
    left: 55%;
    color: #007BC9;
    font-weight: 700;
    font-size: 0.7rem;
}
.myDeliveredBox{
    border: 1px solid green;
    border-radius: 5px;
    padding: 0px 5px;
    width: auto;
    position: relative;
    top: 12px;
    left: -5% ;
    color: green;
    box-shadow: 0px 0px 10px #E9E9E9;
    background-color: #fff;
}
.myCs>div{
    width:'100%';
    background-color: #fff;
    z-index: 999999;
}
.loaderDiv {
    width: 10%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    height: 50vh;
}

.loaderDivNone {
    display: none;
}

.loadSpinnder {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: cyan; */
}

.loadingSpinner {
    color: #007BC9;
}

.pointLoader {
    position: relative;
    left: 14px;
}

.loadingSpinnerOrange {
    margin-left: 10px;
    color: #ff7200;
}

.productSetDiv {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
}
.productSetDivWidth{
    width: auto;
}
.productSetDivWidth>div{
    width: 220px;
    background-color: #fff;
}
.product60span {
    width: auto;
    color: #000;
    font-size: 0.8rem;
    font-weight: 700;
    margin-right: 10px;
}

.productSave {
    width: 65px;
    height: 36px;
    background-color: #FFE000;
    color: #007BC9;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    font-size: 0.9rem;
    font-weight: 800;
}
.productSave:hover {
    border: 1px solid #007BC9;
    transition: border 0.3s ease; /* Smooth transition */
}

.circleInfo {
    position: relative;
    left: 9px;
    cursor: pointer;
}

.circleInfo:hover>.driverModal {
    position: absolute;
    top: -115px;
    right: 5px;
    border-radius: 9px;
    width: 260px;
    height: 120px;
    background-color: grey;
    z-index: 10;
    background-color: #fff;
    border: 3px solid #727376;
    display: block;
}

.driverModal {
    position: absolute;
    bottom: -115px;
    right: -3px;
    border-radius: 9px;
    width: 250px;
    height: 110px;
    background-color: grey;
    z-index: 10;
    background-color: #fff;
    border: 2px solid #727376;
    display: none;
    z-index: 101;
}
.trBorder:nth-child(even){
    border-bottom: 5px solid #DCDCDC;
}
.deAll>.circleInfo:hover+.driverModal {
    position: absolute;
    bottom: -115px;
    right: -3px;
    border-radius: 9px;
    width: 250px;
    height: 110px;
    background-color: grey;
    z-index: 10;
    background-color: #fff;
    border: 3px solid #727376;
    display: block !important;
    z-index: 101;
    box-shadow: 0px 0px 10px grey;
}

.driverModal:before {
    border-bottom: 10px solid #727376;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: 31px;
    top: -10px;
}

.driverR {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1px;
}

.d100 {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F0F0;
}

.driverhead {
    color: #000 !important;
    font-size: 0.7rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 4px;
    padding-left: 10px;
}

.driverValue {
    color: #007BC9 !important;
    /* margin-left: 12px; */
    font-size: 0.7rem;
}

.hubUploadIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0px;
    right: 20px;
    display: flex;
    align-items: center;
    border-left: none;
    border-radius: 4px;
    justify-content: center;
}

.updateCalender {
    font-size: 0.68rem;
    cursor: pointer;
    color: #000;
    position: absolute;
    display: flex;
    right: -10px;
    top: 6px;
}

.hideCalend {
    overflow: hidden;
    position: relative;
    left: 13px;
    z-index: 10;
    opacity: 0;
}

.calenderInpCont {
    width: 90%;
    height: 29px;
    padding-left: 5px;
    background-color: #fff;
    border: 1px solid silver;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.stylemOre {
    width: 45%;
    border-radius: 2px !important;
    border-right: 1px solid rgb(0 0 0 / 6%);
    text-align: center;
}

.stylemOreL {
    width: 45%;
    border-radius: 2px !important;
    border-left: none;
    text-align: center;
}

.newOrgin {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newgroup {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.reactSelect>div {
    flex-basis: 90%;
    background-color: #fff;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
}

.totalAbs {
    position: absolute;
    bottom: -13px;
    color: #007BC9;
    font-weight: 800;
}

.alignCen {
    display: flex;
    margin-left: 5px;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #007BC9;
    color: white;
    font-size: 0.75rem;
}

.select2-results__option--selectable {
    cursor: pointer;
    font-size: 0.75rem;
}

.addresss-map-container{
    width: 100%;
    position: relative;
    z-index: 1000;
}

.addLocation {
    position: absolute;
    /* width: 14%; */
    height: 30px;
    padding: 0px 10px;
    background-color: #007BC9;
    color: #fff;
    border-radius: 3px;
    border: none;
    top: 11px;
    left: 10px;
    cursor: pointer;
}

.addLocation-back{
    position: absolute;
    width: 70px;
    height: 30px;
    padding: 0px 10px;
    background-color: #fff;
    color: black;
    border-radius: 3px;
    font-weight: 900;
    border: none;
    top: 11px;
    right: 50px;
    cursor: pointer;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
}

.newReq>div {
    width: 85%;
    background-color: #fff;
}

.slotTime {
    width: 42%;
    height: 30px;
    border: 1px solid hsl(0, 0%, 80%);

}

.rb {
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-right: 0px;
}

.lb {
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.custom40Div>div {
    min-width: 70%;
    /* display: flex; */
    text-align: start;
    background-color: #Fff;
}

.clusterFixed {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    margin-bottom: 20px;
    border: 3px solid #303030;
    border-radius: 1px;
    position: relative;
    background-color: #303030;
    /* box-shadow: 0px 0px 6px #ff7200 inset; */
}

.clusterFixed>span {
    color: #fff;
    font-size: 18px;
    font-weight: 800;
}

#clusterBtn {
    width: auto;
    height: 26px;
    color: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: #ff7200;
}

#ltnRemoveBtn {
    width: auto;
    height: 26px;
    color: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: red;
    margin-left: 10px;
}

.clusterList {
    width: 300px;
    position: absolute;
    top: 60px;
    right: 0px;
    z-index: 1000;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px black;
}

.alignBdr {
    width: 100%;
    height: auto;
    max-height: 350px;
    overflow: auto;
    margin-bottom: 5px;
}

.tripLists {
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    border-radius: 8px;
    background-color: rgb(0 0 0 / 4%);
    margin-bottom: 11px;
    box-shadow: 0px 0px 2px silver;
    align-items: center;
}

.tripLists:hover {
    box-shadow: 0px 0px 12px silver;
}

.activeTripLists {
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    border-radius: 8px;
    background-color: #fa8128;
    margin-bottom: 11px;
    box-shadow: 0px 0px 6px black;
    align-items: center;
}

.tripDetails {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.startSpan {
    color: green !important;
    font-size: 11px !important;
    font-weight: 900 !important;
}

.otherspan {
    color: #000 !important;
    font-size: 10px !important;
    font-weight: 700 !important;
}

.activespan {
    color: #fff !important;
    font-size: 10px !important;
    font-weight: 700 !important;
}

.tripDetails1 {
    width: 50%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.tripDetails1>div {
    width: 82%;
    background-color: #fff;
    font-size: 0.7rem;
}

.clusterList {
    display: none;
}

.clusterList.slideIn {
    width: 330px;
    min-height: auto;
    position: absolute;
    /* overflow: auto; */
    top: 60px;
    display: block;
    right: 0px;
    z-index: 1000;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px black;
    animation: slideIn 0.5s forwards;
    -webkit-animation: slideIn 0.5s forwards;
    border: 2px solid rgb(0 0 0 / 24%);
}

.slideOut {
    position: absolute;
    right: 0px;
    animation: slideOut 0.5s forwards;
    -webkit-animation: slideOut 0.5s forwards;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        width: 0px;
    }

    100% {
        transform: translateX(0%);
        width: 350px;
        /* background-color: transparent; */
        border: none;
    }
}

@keyframes slideOut {
    0% {
        width: 350px;
        transform: translateX(0%);

    }

    100% {
        width: 0px;
        transform: translateX(0%);
        opacity: 0;
    }
}

.clusterClose {
    position: absolute;
    right: 0px;
}

.circleClose {
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}

.reqNew>div {
    /* width: 40%; */
    margin-right: 5px;
}

.addAddressSlot>div {
    width: 33%;
    margin-right: 5px;
    background-color: #fff;
}

.slotElementn {
    width: 89%;
    display: flex;
}

.slotElementn>div {
    width: 63%;
    margin-right: 10px !important;
    background-color: #fff !important;
}

.overlayModal {
    background-color: #00000087;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 101% !important;
    z-index: 1980;
}

.overlayModalFixed {
    position: absolute;
    /* opacity: 0.1; */
    background-color: #F0F0F0;
}

.modebar {
    display: none !important;
}

.custOverlay>span {
    color: #000;
    font-size: 0.9rem;
    font-weight: 700;
}

.overlayContent {
    position: relative;
    width: 100%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1980;
}

.routePreview {
    font-size: 1rem;
    color: #fff;
    background-color: transparent;
    letter-spacing: 1px;
    font-weight: 500;
    z-index: 2000;
    background-color: transparent;
}

.timeHeadings {
    width: 100%;
    height: 50px;
    display: flex;
}

.timeHead1 {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid rgb(0 0 0 / 12%);
}

.timeHead2 {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    border-right: 1px solid rgb(0 0 0 / 12%);
}

.timeHead3 {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.timeHeadspan {
    font-size: 0.65rem !important;
    color: #000 !important;
    font-weight: 800 !important;
}

.timeHeadspanNew {
    font-size: 0.7rem !important;
    color: #fff !important;
    font-weight: 800 !important;
}

.tripResponse {
    width: 100%;
    height: auto;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    background-color: rgb(0 0 0 / 4%);
    border-radius: 0px;
    border-bottom: 1px solid rgb(0 0 0 / 8%) !important;
    border-left: 1px solid rgb(0 0 0 / 20%) !important;
    border-right: 1px solid rgb(0 0 0 / 20%) !important;
}

.newcollapse-view {
    height: 35px;
    overflow: hidden;
    position: relative;
}

.newcollapse-view>.collapsable_icon {
    position: absolute;
    right: 15px;
    top: -3px;
    height: 35px;
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
}

.newcollapse-view>.collapsable_icon:after {
    content: "\25be";
    color: white;
    font-weight: bold;
}

.newcollapse-view.open {
    overflow: unset;
}

.reduceMargin {
    margin-bottom: 1rem !important;
}

.newcollapse-view.open>.collapsable_icon:after {
    content: "\25be";
    transform: rotate(180deg)
}

.slotStatusEnableIcon {
    color: green;
    width: 20px;
    border: 1px solid green;
    padding: 2px;
    margin-left: 13px;
    cursor: pointer;
}

.slotStatusDisableIcon {
    color: red;
    width: 20px;
    border: 1px solid red;
    padding: 2px;
    margin-left: 13px;
    cursor: pointer;
}

.warningDiv {
    width: auto;
    height: auto;
    padding: 5px;
    position: absolute;
    background-color: #404040;
    border-radius: 4px;
    bottom: 9px;
    display: none;
}

.warningDiv::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: -7px;
    left: 48%;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #404040;
    overflow: hidden;
}

.warningDiv>.warning {
    color: #fff;
    text-align: center !important;
    font-size: 0.7rem;
    font-weight: 300;
}

.newCustBtn {
    width: auto;
    height: 29px;
    padding-left: 13px;
    padding-right: 13px;
    background-color: #007BC9;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.newCustBtn:hover>.warningDiv {
    display: block !important;
    width: auto;
    height: auto;
    padding: 5px;
    position: absolute;
    background-color: #404040;
    border-radius: 4px;
    right: 34%;
    z-index: 999999;
    bottom: 12px;
}

.addSlotsBtn {
    background-color: green;
    color: #fff;
    height: 23px;
    width: auto;
    border-radius: 4px;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 6px;
    cursor: pointer;
}

.modiFyModel {
    width: auto;
    height: auto;
    display: flex;
    justify-content: space-between;
}

.modiFyModel>div {
    width: 45%;
    background-color: #fff;
}

.addCtegoryModal {
    width: 360px !important;
    position: absolute;
    top: -154px;
    left: -15%;
    height: 144px;
    background-color: #727376;
    padding: 2px;
    border-radius: 8px;
    border: 3px solid #727376;
}

.addCtegoryModal:before {
    border-top: 10px solid #888888;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 45%;
    bottom: -11px;
}

.addCategInner {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-around;
    background-color: #fff;
    padding: 8px;
}

.contSelect {
    width: 100%;
    border-radius: 3px;
    height: 31px;
}

.categoryModalSpan {
    font-size: 0.8rem;
    color: #000;
    font-weight: 700;
}

.categoryModalInput {
    height: 30px;
    border-radius: 5px;
    border: 1px solid silver;
}

.categoryModelBtn {
    width: 22%;
    height: 23px;
    border-radius: 3px;
    background-color: #727376;
    color: #fff;
    cursor: pointer;
    border: none;
}

.closeContModal {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
    background-color: #727376;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: -10px;
    right: -8px;
    cursor: pointer;
}

#headingCross {
    color: #fff;
}

.typeContainerOuter {
    width: 100%;
    height: 50px !important;
    display: flex;
}

.tCont50 {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tHead {
    font-size: 0.74rem;
    font-weight: 700;
    color: #000;
    padding-left: 10px;
    color: #000;
}

.tSpan {
    color: #ff7200;
    font-size: 0.85rem;
    font-weight: 700;
    padding-left: 8px;
}

.typeContainer {
    width: 80%;
    height: 100%;
    display: flex;
    text-align: start;
    align-items: center;
}



.typeName {
    color: #ff7200;
    font-size: 0.85rem;
    font-weight: 700;
    padding-left: 10px;
}

.qtyContainer {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.qtyOper {
    width: 33%;
    height: 70%;
    /* background-color: rgb(0 0 0 / 5%); */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.qtySub {
    color: red;
    font-size: 1rem;
    cursor: pointer;
}

.qtyNum {
    color: #000;
    font-weight: 700;
}

.qtyAdd {
    color: green;
    font-size: 1rem;
    cursor: pointer;
}

.addBdr {
    background-color: #fff;
    border-radius: 4px;
}

.inputDiv {
    width: 100%;
}

.inputDivNew {
    width: 100%;
}

.addons {
    margin-Bottom: 15px;
    background: rgb(0 0 0 / 3%);
    padding-top: 20px;
    padding-bottom: 20px;
}

.inputElmOuter {
    width: 100%;
    height: auto;

}

.inputHead {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
}

.io {
    font-size: 1.2rem;
    padding-left: 4px;
    font-weight: 900;
    color: #000;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.ionew {
    padding-left: 13px;
}

.colorCodes {
    width: 10%;
    height: 49px;
}

.colorRow {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.round {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #04AA6D;
}

.roundColor {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #505050;
}

.colorNameGreen {
    width: 70%;
    color: #04AA6D;
    font-weight: 800;
}

.colorNameGrey {
    width: 70%;
    color: #727376;
    font-weight: 800;
}

.inputElements {
    width: 100%;
    height: 110px;
    margin-top: 20px;
    padding: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid rgb(0 0 0 / 19%);
    border-radius: 4px;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 12%);
    overflow: auto;
}

.inpElemAdd {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

.inputElements:hover {
    /* background-color: rgb(0 0 0 / 6%); */
    box-shadow: 0px 0px 16px rgb(0 0 0 / 22%);
}

.inputComponent {
    width: auto;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #007BC9;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: #FFE000;
    margin-left: 10px;
    padding-left: 10px;
    font-weight: 700;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.outputComponent {
    width: auto;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: #505050;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.draggableIoOuter {
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.dragHead {
    width: 100%;
    height: 100%;
    display: flex;
    border: 1px solid silver;
}

.dragHeadings {
    width: 50%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    background-color: #191d1d;
}

.droppable {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
    border: 1px solid silver;
}

.dragDotted {
    width: 99.6%;
    height: auto;
    min-height: 260px;
    /* border: 2px dashed #E0E0E0; */
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 700;
    color: #727376;
    cursor: pointer;
    background-color: #fff;
}

.dragDotted:hover {
    background-color: rgb(0 0 0 / 2%);
}

.dragDottedNew {
    width: 100%;
    height: auto;
    min-height: 140px;
    /* border: 2px dashed #E0E0E0; */
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 700;
    color: #727376;
    cursor: pointer;
}

.dropZone {
    width: 49.6%;
    height: 100%;
    min-height: 260px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    overflow: auto;
    border: 2px dashed #808080;
}

.inputComponentDragged {
    width: auto;
    position: relative;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: #228622;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.inputComponentDraggedOutPut {
    width: auto;
    position: relative;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: #505050;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.groupCrossComp {
    position: absolute;
    top: -6px;
    font-size: 14px;
    right: -3px;
    color: red;
    font-weight: 800;
    cursor: pointer;
}

.undraggableItemgreen {
    width: auto;
    position: relative;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #007BC9;
    border: none;
    border-radius: 6px;
    cursor: not-allowed;
    background-color: #D2C350;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.undraggableItemgrey {
    width: auto;
    position: relative;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: not-allowed;
    background-color: #c76a12;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.dropZoneCentre {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    font-weight: 700;
}

.solutionsOuter {
    width: 100%;
    /* height: 500px; */
}

.solutionsInner {
    width: 100%;
    height: auto;
    background-color: rgb(0 0 0 / 3%);
}

.solutionHeader {
    width: 100%;
    height: 38px;
    background-color: #383838;
    display: flex;
    text-align: start;
    align-items: center;
}

.solHeaderSpan {
    color: #fff;
    font-size: 1rem;
    font-weight: 800;
    padding-left: 13px;
    letter-spacing: 0.8px;
}

.solutionTable {
    width: 100%;
    height: auto;
    margin-top: 10px;
    padding: 12px;
    background-color: #fff;
}

.loadDistBtn {
    width: auto;
    height: 26px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #007BC9;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
}

.hideBorderDiv1 {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0%;
    background-color: #fff !important;
}

.hideBorderDiv2 {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50%;
    background-color: #fff !important;
}

.solTableHead {
    width: 100%;
    height: 40px;
    display: flex;
    border: 1px solid rgb(0 0 0 / 16%);
    background-color: #fff;
}

.solCol {
    background-color: rgb(0 0 0 / 2%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.sol10 {
    width: 10%;
    height: 100%;
    border: 1px solid rgb(0 0 0 / 16%);
    border-left: none;
    border-top: none;
    border-bottom: none;
    color: #000;
    font-weight: 700;
}

.sol40 {
    width: 30%;
    height: 100%;
    border: 1px solid rgb(0 0 0 / 16%);
    border-left: none;
    border-top: none;
    border-bottom: none;
    color: #000;
    font-weight: 700;
}

.sol16 {
    width: 17%;
    height: 100%;
    border: 1px solid rgb(0 0 0 / 16%);
    border-left: none;
    border-top: none;
    border-bottom: none;
    color: #000;
    font-weight: 700;
}

.solTableBody {
    width: 100%;
    height: 80px;
    display: flex;
    border: 1px solid rgb(0 0 0 / 16%);
    border-top: none;
    /* border-right: none; */
    position: relative;
}

.solTabSpan {
    color: grey !important;
}

.vehTypeOuter {
    width: 100%;
    height: 500px;
    display: flex;
}

.vehTypeLeft {
    width: 20%;
    height: 100%;
    /* border: 1px solid rgb(0 0 0 / 2%); */
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0px 0px 4px silver inset;
}

.vehTyleHeader {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    background-color: #383838;
}

.vehTypeLists {
    width: 100%;
    height: 92%;
    padding: 12px;
    overflow: auto;
    background-color: rgb(0 0 0 / 3%);
}

.vehicleType {
    width: 100%;
    height: auto;
    padding: 7px;
    background-color: #007BC9;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-bottom: 13px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px grey;
    /* box-shadow: 0px 0px 3px grey; */
}

.vehicleTypeInactive {
    width: 100%;
    height: auto;
    padding: 7px;
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    cursor: pointer;
    margin-bottom: 13px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px grey;
    border: 1px solid silver;
}

.vehicleTypeInactive:hover {
    box-shadow: 0px 0px 5px grey;
    color: #ff7200;
    cursor: pointer;
}

.vehicleType:hover {
    box-shadow: 0px 0px 5px grey;
    cursor: pointer;
}

.vehTypeRight {
    width: 80%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgb(0 0 0 / 1%);
    overflow: hidden;
    box-shadow: 0px 0px 7px silver inset;
    margin-left: 7px;
}

.overContainer {
    width: 26%;
    height: auto;
    position: absolute;
    top: 8px;
    right: 8px;
    border: 1px solid rgb(0 0 0 / 9%);
    border-radius: 4px;
    box-shadow: 0px 0px 10px grey;
    cursor: pointer;
    opacity: 1;
    z-index: 1;
}

.clip {
    width: 40px;
    height: 23px;
    position: absolute;
    top: 10px;
    left: -41px;
    background-color: #484848;
    cursor: pointer;
    border: none;
    box-shadow: 0px 0px 10px grey;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /* background-color: #ff7200; */
}

.roundCircleNew {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 5px;
    right: 21px;
    border-radius: 50%;
    background-color: #fff;
}

.overContainer.open {
    width: 24%;
    animation-duration: .5s;
    animation-name: slide-open;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0px 0px 16px darkgray;
}

@keyframes slide-open {
    0% {
        max-width: 0px;
        opacity: 0;
    }

    100% {
        max-width: 24%;
        opacity: 1;
    }
}

.overContainer.close {
    animation-duration: .2s;
    animation-name: slide-close;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes slide-close {
    0% {
        max-width: 20%;
        opacity: 0;
        box-shadow: none;
    }

    100% {
        position: absolute;
        right: -20%;
        max-width: 20%;
        opacity: 1;
        box-shadow: none;
    }
}

.containerRow {
    width: 100%;
    height: 39px;
    display: flex;
    border: 1px solid rgb(0 0 0 / 12%);
}

.containerRowHeader {
    background-color: #282828;
}

.boxColor {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorDiv {
    width: 14px;
    height: 14px;
    background-color: crimson;
    border-radius: 50%;
}

.boxName {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #484848;
    font-weight: 700;
}

.boxQty {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #484848;
    font-weight: 700;
}

.bn50 {
    width: 70%;
    color: #000;
    font-size: 0.9rem;

}

.contSpan {
    color: grey;
}

.newcontSpan {
    color: #ff7200;
    font-weight: 800;
    font-size: 0.9rem;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.boxTabHead1 {
    color: #000 !important;
    font-weight: 800;
    position: relative;
    top: 5px;
    padding-left: 4px;
}

.boxTabHead {
    color: #000 !important;
}

.vehTypeCont {
    width: auto;
    position: relative;
    height: 25px;
    padding-left: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 7px;
    background-color: #ff7200;
    color: #fff;
}

.value>div {
    width: 94%;
    background-color: #fff;
}

.otpModule {
    width: 100%;
    height: 155px;
}

.otpHeader {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.otpHeader>span {
    color: #000;
    font-weight: 700;
    font-size: 1rem;
}

.otp-input {
    width: 90%;
    height: 120px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.input-1,
.input-2,
.input-3,
.input-4 {
    width: 11%; 
    height: 40px;
    font-family: GOTHIC;
}

.colorblack {
    color: #fff;
    letter-spacing: 0.4px;
    font-weight: 500;
}

.otp-field {
    width: 100%;
    height: 100%;
    border: 1px solid silver;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 7%) inset;
    text-align: center;
    font-size: 33px;

}

.nodatafound {
    width: 100%;
    height: 84vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nodatafound>span {
    color: red;
    font-size: 1rem;
}

.mobileView {
    display: none;
}

.solAbs1 {
    position: relative;
    top: -50%;
    right: 0px;
}

.tripSpana {
    font-size: 0.9rem;
    color: #007BC9;
    font-weight: 800;
    margin-left: 10px;
    text-decoration: underline;
    text-underline-offset: 4px;
    letter-spacing: 1px;
}

.spanOr {
    font-size: 0.9rem;
    font-weight: 800;
    color: #007BC9;
    text-decoration: underline;
    text-underline-offset: 4px;

}

.allDivabs {
    width: auto;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -3%;
}

.selectAll {
    font-size: 0.7rem;
    color: #000;
    font-weight: 700;
}

.customCheckbox1[type=checkbox] {
    position: relative;
    cursor: pointer;
    width: 1px;
    height: 20px;
    position: relative;
    right: 11px;
}

.customCheckbox1[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    border: 3px solid #ff7200;
    border-radius: 20px;
    background-color: #ff7200;
    transition: all 0.2s linear;
}


.customCheckbox1[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    border: 3px solid #ff7200;
    left: 20px;
    top: 0px;
    border-radius: 3px;
    background-color: #fff;
}


.customCheckbox1[type=checkbox]:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border: solid #FFCB9A;
    border-width: 0 0px 0px 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    position: absolute;
    top: 0px;
    left: 50px;
    transition: all 0.2s linear;
}

.customCheckbox1[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 5px 5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 3.3px;
    left: 27.4px;
}

.overlayNone {
    visibility: hidden;
}

.loadingSpinnerdown {
    position: relative;
    top: 26px;
}

#scene {
    width: 100% !important;
    height: 100% !important;
    z-index: 10;
}

.solAbs {
    position: relative;
    right: 0px;
}

.warningBoxx {
    width: 100px;
    position: absolute;
    padding: 2px;
    height: auto;
    color: #fff;
    font-weight: 300;
    border-radius: 4px;
    bottom: 33px;
    left: 18%;
    display: none;
    background-color: red;
}

.warningBoxx:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 6px solid red;
    content: "";
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 44%;
}

.solAbs button:hover>.warningBoxx {
    background-color: red;
    position: absolute;
    bottom: 33px;
    left: 18%;
    display: block;
}

.infeasibleOuter {
    width: 100%;
    height: auto;
}

.infeasibleHeader {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    background-color: rgb(0 0 0 / 4%);
}

.infeasibleText {
    color: #000;
    font-size: 1.1rem;
    padding-left: 5px;
    font-weight: 700;
}

.infeasibleNum {
    color: red;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.infeasibleBody {
    width: 98%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    position: relative;
    top: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    border-radius: 3px;
    overflow: auto;
}

.infeasibleReq {
    width: 70%;
    height: 90px;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 5px #BEBEBE;
    cursor: pointer;
}

.infeasibleReq:hover {
    box-shadow: 0px 0px 12px #BEBEBE;
    cursor: pointer;
}

.feasibleReason {
    width: 28%;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    padding-left: 14px;
    cursor: pointer;
    animation-duration: 1s;
    animation-name: slideDown;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    background-color: rgb(0 0 0 / 6%);
    box-shadow: 0px 0px 12px #BEBEBE;
    border: 1px solid rgb(0 0 0 / 9%);
    /* box-shadow: 0px 0px 12px #BEBEBE; */

}

.feasibleReason:hover {
    background-color: #F8F8F8;
}

@keyframes slideDown {
    0% {
        opacity: 1;
        position: relative;
        left: 0px;
    }

    100% {
        opacity: 1;
        position: relative;
        left: 2px;
    }

}

.feasibleReason:before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid red;
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 39.7%;
    left: -4.2%;
}

.requestFailText {
    color: red;
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: 0.4px;
}

.feasibleSno {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #007BC9;
    position: absolute;
    left: -10px;
    top: -10px;
}

.infeasibleReqHead {
    width: 100%;
    height: 45px;
    display: flex;
}

.infeasibleBlack {
    width: 13%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #727376;
    font-size: 0.8rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.infeasibleOrange {
    width: 87%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #007BC9;
    font-size: 0.85rem;
    font-weight: 700;
    text-align: left;
}

.infeasibleFlex {
    display: flex;
}

.infeasibleReqHead33 {
    width: 33%;
    height: 45px;
}
#myycs>div{
    background-color: #fff;
}
.infeasibleTime {
    width: 42%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #727376;
    font-size: 0.8rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.infeasibleInp {
    width: 58%;
    height: 100%;
    color: #000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #007BC9;
    font-size: 0.9rem;
    font-weight: 700;
}

.feasibleTail {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 11px;
}

.feasibleBtn {
    width: auto;
    height: 28px;
    background-color: #007BC9;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    margin-right: 10px;
}

.reqAbs {
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #007BC9;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: -10px;
    left: -10px;
}

.allotedDrop>div {
    width: 98%;
    background-color: #fff;
}

.deliveredSpanActive {
    color: #ff7200;
    font-weight: 900;
    font-size: 0.95rem;
}

.deliveredSpan {
    color: #727376;
    font-size: 0.9rem;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.customizeBtn {
    height: 27px;
    background-color: #727376;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    border: none;
}

.customParent {
    width: 100%;
    height: auto;
}

.customizeHeader {
    width: 100%;
    height: 190px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 10px;
    background-color: rgb(0 0 0 / 3%);
    margin-bottom: 8px;
}

.customizeTable {
    width: 22%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    border-radius: 4px;
    margin-left: 14px;
    border: 1px solid silver;
    box-shadow: 0px 0px 10px grey;
    padding: 2px;
}

.moduleName {
    width: 100%;
    height: 54px;
    text-align: center;
    font-size: 0.9rem;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-weight: 900;
    background-color: rgb(0 0 0 / 21%);
}

.requestCustList {
    width: 100%;
    height: 142px;
}

.moduleComps {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #ff7200;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 700;
    border-bottom: 1px solid silver;
}

.moduleComps:hover {
    background-color: #fff;
    color: #ff7200;
    box-shadow: 0px 0px 16px grey;
}

.moduleCompsActive {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 700;
    border-bottom: 1px solid silver;
    background-color: #ff7200;
    color: #fff;
}

.customBody {
    width: 100%;
    height: auto;
    /* padding: 13px; */
}

.dropZoneNew {
    width: 100%;
    height: 100%;
    min-height: 140px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    overflow: auto;
    border-width: 2px;
    border-style: dashed;
    border-color: rgb(128, 128, 128);
}

.tripLocationListing {
    width: 100%;
    height: 240px;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    box-shadow: 0px 0px 1px grey inset;
    margin-bottom: 0px;
}

.addmore{
    display: flex;
    width: 10%;
    height: inherit;
    border: 1px dotted #ccc;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
}
.carettDiv{
    width: 3%;
}
.wid95 {
    /* min-width: 84%; */
    max-width: 100px; 
    height: 100%;
    display: flex;
    align-items: flex-start;
    vertical-align: bottom;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #fff;
    border: 1px solid rgb(0 0 0 / 10%);
    padding-left: 20px;
    padding-right: 20px;
}
.tripAddress {

    min-width: 370px;
    max-width: 370px;
    height: 67%;
    padding-left: 10px;
    padding-right: 10px;
    color: #000;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #fff;
    border: 1px dashed #727376;
    border-radius: 26px;
    color: #000;
    font-weight: 700;
    font-size: 0.7rem;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(0 0 0 / 1%);
    position: relative !important;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.order-arrival-timing{
    border: 1px solid green;
    color: green;
    font-weight: 600;
    text-wrap-mode: nowrap;
    padding: 0px 2px;
    border-radius: 5px;
}
.tripRow{
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    white-space: normal;
}

.totalLocatuu{
    position: absolute;
    color: #007BC9	;
    text-underline-offset: 4px;
    font-weight: 800;
    /* z-index: 90; */
    margin-top: 3px;
    margin-left: -10px;
}

.tripAddress:hover{
    box-shadow: 0px 0px 10px grey;
    cursor: pointer;
}
.tripAddressActive {
    min-width: 370px;
    max-width: 370px;
    height: 67%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #FFE000;
    border-radius: 26px;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    box-shadow: 1px 1px 10px grey;
    text-align: center;
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tripAddressActive:hover {
    cursor: pointer;
    box-shadow: 0px 0px 17px rgb(0 0 0 / 33%);
}

.wid95::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    cursor: pointer;
    /* padding: 2px; */
}

.wid95::-webkit-scrollbar-track {
    width: 5px;
    background-color: #f5f5f5;
    box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.wid95::-webkit-scrollbar-thumb {
    background-color: #727376;
    width: 5px;
    cursor: pointer;
    box-shadow: inset 0 0 0 6px rgba(0, 0, 0, .1);
}

.tripArrow {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faRight {
    color: #ff7200;
    font-size: 1.2rem;
    font-weight: 700;
    animation: bounceRight 1.5s infinite;
    position: relative;
    bottom: 0px;
}

.faRights {
    color: #000;
    font-size: 0.97rem;
    position: relative;
    bottom: 0px;
}

@keyframes bounceRight {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateX(-3px);
    }

    60% {
        transform: translateX(3px);
    }
}

.carett {
    font-size: 1.6rem;
    cursor: pointer;
    color: #007BC9;
}

.carett:hover {
    font-size: 2rem;
    cursor: pointer;
    color: #007BC9;
}

.user100 {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
    background-color: rgb(0 0 0 / 2%);
    padding-bottom: 12px;
}

.user33 {
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    flex-wrap: wrap;
    margin-top: 15px;
}

.user332 {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 4%);
}

.user332>div {
    width: 23%;
    margin-left: 16px;
    background-color: #fff;
    text-align: start;
    border-radius: 5px;
}

.userSpan {
    font-size: 0.9rem;
    font-weight: 700;
    color: #696c74;
}

.userSpanBlack {
    color: #000;
}

.userInp {
    width: 100%;
    height: 36px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 5px;
    margin-top: 10px;
    font-size: 0.8rem !important;
}

.user33>div {
    width: 100%;
    margin-top: 10px;
    text-align: start;
}

.etd {
    width: 100%;
    height: auto;
}

.etdCreation {
    width: 100%;
    height: auto;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    width: 100%;
}

.etdHeading {
    height: 35px;
    justify-content: space-between;
    padding-left: 10px;
    position: relative;
    width: 100%;
    align-items: center;
    background-color: #FFE000;
    color: #007BC9;
    display: flex;
}

.etdHeading>span {
    color: #007BC9;
    font-size: .9rem;
    font-weight: 700;
}

.etdDynamic {
    width: 100%;
    height: auto;
    padding: 14px;
    display: flex;
    justify-content: space-between;
}

.etdLeft {
    width: 100%;
    height: 100%;
}

.etdLeftInner {
    width: 98%;
    height: auto;
    min-height: 195px;
    border-radius: 10px;
    padding: 11px;
    border: 1px solid rgb(0 0 0 / 10%);
}

.etdDropZone {
    width: 100%;
    height: 100%;
    border: 2px dashed #a8a8a8;
    border-radius: 8px;
    cursor: pointer;
}

.etdDropZoneNew {
    width: 100%;
    height: auto;
    min-height: 195px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 14px;
}

.inputDiv {
    width: 25%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(0 0 0 / 12%);
    margin-right: 11px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 11%);
}

.inputDivInp {
    width: 100%;
    height: 32px;
    border-radius: 4px;
    border: 1px solid silver;
    padding-left: 4px;
}

.dropHere {
    font-size: 1rem;
    font-weight: 700;
}

.etdDropZone:hover {
    border: 2px dashed rgb(0 0 0 / 19%);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
}

.etdMinus {
    background-color: red;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: .7rem;
    height: 14px;
    position: absolute;
    right: -2px;
    top: -7px;
    width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.saveEtd {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: right;
    align-items: flex-end;
}

.etdSaveBtn {
    width: auto;
    height: 28px;
    padding-left: 17px;
    padding-right: 17px;
    background-color: #007BC9;
    color: #fff;
    border-radius: 4px;
    border: none;
    margin-right: 22px;
    cursor: pointer;
}

.etdRight {
    width: 30%;
    height: 220px;
}

.etdRightHeader {
    display: flex;
    height: 18%;
    width: 100%;
    background-color: #FFE000;
    color: #007BC9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.99rem;
}

.etdRightBody {
    width: 100%;
    height: 82%;
    display: flex;
    justify-content: space-around;
    border: 1px solid rgb(0 0 0 / 10%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 7px;
}

.etdRightColumn {
    width: 96%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(0 0 0 / 3%);
}

.etdRightColumn:hover {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 19%);
}

.etdDraggable {
    width: 100%;
    /* background-color: red; */
}

.etdDragField {
    width: 90%;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 0px 9px rgb(0 0 0 / 9%);
}

.etdDragField:hover {
    background-color: #585858;
    color: #fff;
    box-shadow: 0px 0px 9px rgb(0 0 0 / 49%);
}

.etdDisplay {
    width: 100%;
    height: auto;
    background-color: #fff;
    margin-top: 25px;
    padding-bottom: 14px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 12%);
}

.etdDisplayHeader {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #000;
    font-size: 1.2rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.etdTimeClauses {
    width: 99%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: #fff;
    margin-top: 14px;

}

.etdLine {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 18px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.etdClause {
    width: 90%;
    height: auto;
    display: flex;
    border-left: 3px solid #727376;
    background-color: rgb(0 0 0 / 4%);
    box-shadow: 0px 0px 4px rgb(0 0 0 / 9%);
}

.etdClause:hover {
    box-shadow: 0px 0px 6px rgb(0 0 0 / 19%);
    cursor: pointer;
}

.getEtd {
    width: 80%;
    height: auto;
    min-height: 45px;
    display: flex;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
}

.etdEdit {
    width: 10%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(0 0 0 / 4%);
}

.etdEdit:hover {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 12%);
}

.etdEditIcon {
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 12px;
    color: green;
}

.etdDeleteIcon {
    font-size: 1.1rem;
    cursor: pointer;
    position: relative;
    bottom: 0px;
    color: red;
}

.etdValueDiv {
    width: 20%;
    height: auto;
    display: flex;
}

.etd50 {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.etdDInput {
    width: 90%;
    height: 33px;
    border-radius: 4px;
    padding-left: 4px;
    border: 1px solid silver;
}

.etd50>div {
    background-color: #fff;
}

.etdSave {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.etdSave>button {
    width: auto;
    height: 32px;
    background-color: #ff7200;
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
}

.noDataDiv {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-size: 1rem;
}

.alignCentNew {
    display: flex;
    justify-content: center;
    align-items: center;
}

.userTypeDivv {
    width: 28%;
    height: 90%;
    position: relative;
    left: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(0 0 0 / 4%);
    /* box-shadow: 0px 0px 12px rgb(0 0 0 / 11%); */
}

.userTypeStatusActive {
    width: 50%;
    height: 100%;
    border: none;
    cursor: pointer;
    background-color: #007BC9;
    border-radius: 3px;
    color: #fff;
    box-shadow: 0px 0px 5px grey;
}

.userTypeStatusinActive {
    width: 50%;
    height: 100%;
    border: none;
    cursor: pointer;
    background-color: rgb(0 0 0 / 1%);
    color: #000;
    border-radius: 3px;
}

.slotTime {
    width: auto;
    height: 18px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: cente;
    top: -10px;
    left: 8%;
    border-radius: 2px;
    background-color: #fff;
    z-index: 100;
    color: green;
    border: 1px solid green;
    font-weight: 800;
    font-size: 0.65rem;
    letter-spacing: 0.4px;
    box-shadow: 0px 0px 3px green;
}

.reached {
    width: auto;
    height: 18px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: cente;
    top: -10px;
    right: 4%;
    border-radius: 2px;
    background-color: #fff;
    z-index: 100;
    color: green;
    border: 1px solid green;
    font-weight: 800;
    font-size: 0.65rem;
    letter-spacing: 0.4px;
    box-shadow: 0px 0px 3px green;
}

.emergency {
    width: auto;
    height: 18px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: cente;
    bottom: -10px;
    margin: auto;
    border-radius: 2px;
    background-color: #fff;
    z-index: 100;
    color: red;
    border: 1px solid red;
    font-weight: 800;
    font-size: 0.65rem;
    letter-spacing: 0.4px;
    box-shadow: 0px 0px 3px red;
}

.skipped {
    width: auto;
    height: 18px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: cente;
    top: -10px;
    margin: auto;
    border-radius: 2px;
    background-color: #fff;
    z-index: 100;
    color: red;
    border: 1px solid red;
    font-weight: 800;
    font-size: 0.65rem;
    letter-spacing: 0.4px;
    box-shadow: 0px 0px 3px red;
}

.buttonQue {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.violated {
    width: auto;
    height: 18px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: cente;
    bottom: -10px;
    margin: auto;
    border-radius: 2px;
    background-color: #fff;
    z-index: 100;
    color: red;
    border: 1px solid red;
    font-weight: 800;
    font-size: 0.65rem;
    letter-spacing: 0.4px;
    box-shadow: 0px 0px 3px red;
}

.otpStatus {
    color: red;
    text-shadow: 0px 0px 14px #DC143C;
}

.otpStatusActive {
    color: #fff;
    text-shadow: 0px 0px 14px #fff;
}

.timely {
    width: auto;
    height: 18px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: cente;
    bottom: -10px;
    margin: auto;
    border-radius: 2px;
    background-color: #fff;
    z-index: 100;
    color: green;
    border: 1px solid green;
    font-weight: 800;
    font-size: 0.65rem;
    letter-spacing: 0.4px;
    box-shadow: 0px 0px 3px green;
}

.customerDetailsDiv {
    width: 100%;
    height: auto;
}

.customerHeader {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(0 0 0 / 11%);
}

.detailSpan {
    font-size: 1.4rem;
    padding-left: 30px;
    font-weight: 800;
    color: #000;
}

.pfs {
    width: 69px;
    height: 92%;
    position: relative;
    right: 20px;
}

.customerBody {
    width: 100%;
    height: auto;
    /* display: flex; */
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 1%);
}

.customerDetails {
    width: 90%;
    height: 90%;
    border-radius: 44px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 20px;
}

.customerRow {
    width: 100%;
    min-height: 45px;
    display: flex;
    padding: 5px;
    margin-bottom: 8px;
    align-items: center;
    border-radius: 18px;
    background-color: rgb(0 0 0 / 4%);
}

.imageDim {
    height: 70%;
    position: relative;
    width: 70%;
}

.customerHead {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px;
    font-size: 0.8rem;
    color: #727376;
    font-weight: 800;
}

.customerValue {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9rem;
    color: #000;
    font-weight: 800;
}

.feedbackDiv {
    width: 100%;
    height: auto;
    border-radius: 11px;
    background-color: rgb(0 0 0 / 6%);
}

.feedbackHeader {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    padding-left: 6px;
    font-size: 0.9rem;
    color: #000;
    font-weight: 800;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.feedbackStar {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.myStar {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #fff;
    cursor: pointer;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 9%);
}

.myStarActive {
    font-size: 1.5rem;
    margin-right: 10px;
    color: yellow;
    cursor: pointer;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 9%);
}

.feedbackComment {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedbackSubmit {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedbackBtn {
    width: auto;
    height: 27px;
    border: none;
    cursor: pointer;
    background-color: #ff7200;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 10%);
}

.asterick {
    color: red;
    font-weight: 800;
}

.eyeAbs {
    position: absolute;
    top: 63%;
    cursor: pointer;
    right: 40px;
}

.profileElem {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 26%);

}

.topProfile {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.profilePic {
    display: flex;
    justify-content: center;
    position: relative;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dotted #007BC9;
    border-radius: 50%;
    height: 200px;
    width: 220px;
}

.profileInfo {
    width: 96%;
    height: auto;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}

.lt {
    align-items: center;
    display: flex;
    width: 100%;
    background-color: rgb(0 0 0/2%);
    border-radius: 9px;
    box-shadow: 1px 1px 4px #888;
    flex-direction: column;
    height: auto;
    margin-top: 12px;
    position: relative;
}

.ltTop {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
}

.ltTop>span {
    color: #000;
    font-size: 1.1rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.ltBot {
    align-items: center;
    display: flex;
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    padding: 8px;
}

.lt40,
.ltRow {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.ltRow {
    border-radius: 4px;
    height: 50px;
    /* padding-left: 12px;
    padding-right: 12px; */
    width: 31%;
}

.lt40 {
    width: 20%;
}

.ltRow {
    min-width: 300px;
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
}

.ltspan {
    color: #000;
    font-size: .8rem;
    font-weight: 700;
}

.lt60 {
    height: 100%;
    width: 60%;

}

.ltInp1.bd,
.ltInp1.nobd {
    border: none;
    border-bottom: 2px solid #007BC9;
    color: #007BC9;
    height: 29px;
    padding-left: 4px;
    width: 90%;
    font-weight: 700;
    background-color: initial;
    cursor: pointer !important;
}

.recomendedText {
    color: red;
    font-weight: 800;
    font-size: 0.7rem;
    position: relative;
    bottom: 6px;
    animation: blinker 1.8s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 1;
        font-size: 0.7rem;
    }

    100% {
        opacity: 1;
        font-size: 0.73rem;
    }
}

.qtyslash {
    position: absolute;
    right: -12px;
    font-weight: 800;
    color: #000;
}

.lengthCheck {
    position: absolute;
    bottom: -7px;
    left: 3px;
    color: red;
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-align: center;
}

.alignCent {
    display: flex;
    justify-content: center;
    /* background-color: rgb(0 0 0 / 10%); */
}

.vehVolelm {
    display: flex;
    justify-content: flex-start;
}

.vehVol {
    font-size: 1em;
    color: #007BC9;
    font-weight: 800;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.notFoundddd {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notFoundddd>span {
    color: red;
    font-size: 1rem;
}

.cashMemo {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 2%);
}
.pg-viewer-wrapper {
    overflow-y: unset !important;
}

.photo-viewer-container {
    width: unset !important;
    height: unset !important;
}

.photo-viewer-container>img {
    width: unset !important;
    height: unset !important;
}

.pg-viewer-wrapper {
    overflow-y: unset !important;
}

.pdfClass {
    width: 100%;
    height: 100%;
}

.viewPdfBtn {
    width: auto;
    height: 33px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ff7200;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px grey;
}

.viewPdfBtn:hover {
    box-shadow: 0px 0px 18px grey;
}

.customDriverDetails {
    width: 100%;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customeInfo {
    font-size: 0.88rem;
    cursor: pointer;

}

.customeInfo:hover {
    font-size: 0.98rem;
}

.customDriverDetails>.customeInfo:hover+.driverModalCustom {
    position: absolute;
    bottom: -110px;

    border-radius: 9px;
    width: 240px;
    height: 110px;
    background-color: grey;
    z-index: 10;
    background-color: #fff;
    border: 3px solid #727376;
    display: block !important;
    z-index: 101;
    box-shadow: 0px 0px 10px grey;
}

.driverModalCustom {
    position: absolute;
    bottom: -110px;
    /* right: 5px; */
    border-radius: 9px;
    width: 240px;
    height: 110px;
    background-color: grey;
    z-index: 10;
    background-color: #fff;
    border: 3px solid #727376;
    display: none;
    z-index: 101;
}

.driverModalCustom:before {
    border-bottom: 10px solid #727376;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: 45%;
    top: -10px;
}

.customeInfo:hover>.driverModalCustom {
    position: absolute;
    bottom: -110px;
    border-radius: 9px;
    width: 240px;
    height: 110px;
    background-color: grey;
    z-index: 10;
    background-color: #fff;
    border: 3px solid #727376;
    display: block;
}

.activeLat {
    color: #007BC9 !important;
    font-size: 0.65rem;
    cursor: pointer;
    /* text-decoration: underline;
    text-underline-offset: 4px; */
    position: relative;
    top: 4px;
}

.inactiveLat {
    color: #000 !important;
    font-size: 0.65rem;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
    position: relative;
    top: 4px;
}

.activeLat:hover {
    color: #ff7200 !important;
}

.inactiveLat:hover {
    color: #ff7200 !important;
}

.deliverDetails {
    width: 98%;
    height: 55px;
    position: fixed;
    bottom: 1px;
    z-index: 100;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 18px #A9A9A9;
    display: flex;
    /* padding: 12px; */
    background-color: #fff;
}
.deliverDetailsTop{
    width: 90%;
    height: auto;
    position: fixed;
    top: 1px;
    z-index: 100;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 18px #A9A9A9;
    display: flex;
    /* padding: 12px; */
    background-color: #fff;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
}
.ddcPadd{
    padding-top: 10px;
    padding-bottom: 10px;
}
.ddcSpan {
    font-size: 12px;
    font-weight: 900;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.ddcVal {
    font-size: 12px;
    color: #007BC9;
    font-weight: 600;
}
.ddcEllip{
    height: auto;
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    color: #007BC9;
}


.ddrHgtAuto {
    min-height: 30px;
    background-color: rgb(0 0 0 / 2%);
}


.ddrclosea {
    position: absolute;
    top: -13px;
    right: -11px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    font-size: 1.2rem;
}

.ddrclose:hover {
    font-size: 1.1rem;
    color: red;
    cursor: pointer;
}

.ddr30 {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
}

.ddr30>span {
    font-size: 0.8rem;
    padding-left: 10px;
    font-weight: 800;
    color: #000;
}

.ddr70 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}

.ddr70>span {
    font-size: 0.75rem;
    overflow-wrap: break-word;
    color: #ff7200;
    font-weight: 600;
}

.ddr100 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.ddr100>span {
    color: green;
    font-size: 0.8rem;
    font-weight: 800;
}


.modalEdit {
    position: absolute;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
    color: blue;
}

.recover-moadal,
.recover-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: block !important;
    z-index: 1111;

}

.recover-overlay {
    background-color: rgba(49, 49, 49, 0.8);
}

.recover-content {
    width: 35%;
    height: 260px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.1;
    background-color: #ff7200;
    padding-top: 35px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 2;
}

.recover_data {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: #fff;

}

.customModasl {
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 999911;
    width: 35%;
    height: 265px;
    background-color: #fff;
    border-radius: 8px;
    border: 3px solid #007BC9;
}

.customReactAddress {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.customReactAddress>span {
    color: #000;
    font-size: 1.3rem;
    font-weight: 800;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.customReactAddress1 {
    width: 100%;
    height: auto;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-top: 13px;
}

.cRAdd30 {
    width: 25%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 800;
}

.cRAdd70 {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
}
.cRAdd70>span{
    width: 20px;
    font-weight: 700;
    font-size: 0.8rem;
}

.cRAdd70>input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding-left: 3px;
    border: 1px solid rgb(0 0 0 / 10%);
    font-size: 0.8rem;
}

.orangeHeader {
    width: 100%;
    height: 40px;
    background-color: #007BC9;
}

.orangeHeaderS {
    width: 100%;
    background-color: #ff7200;
}

.customReactAddress2 {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CRB {
    width: auto;
    height: 32px;
    background-color: #007BC9;
    color: #fff;
    cursor: pointer;
    padding-left: 14px;
    padding-right: 14px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px grey;
}

.CRB:hover {
    box-shadow: 0px 0px 4px grey;
}

.otpfieldEelm {
    width: 74%;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.otpfield {
    border: 1px solid silver;
    box-shadow: inset 1px 1px 10px rgb(0 0 0 / 7%);
    font-size: 33px;
    height: 100%; 
    text-align: center;
    width: 15%;
}
.otp__digit{
    height: 40px;
    width: 40px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #007BC9;
    text-align: center;
    outline: none;
    font-size: 16px;
   
}
.otp__digit__white{
    height: 40px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid #007BC9;
    text-align: center;
    outline: none;
    font-size: 16px;
    background-color: #fff;
    box-shadow: 0px 0px 10px grey;
}
.otp__digit__grey{
    height: 40px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid #007BC9;
    text-align: center;
    outline: none;
    font-size: 16px;
    background-color: #fff;
}

.customRMssg {
    width: 350px;
    height: 90px;
    position: absolute;
    bottom: 50%;
    left: 0%;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    box-shadow: 0px 0px 10px grey;
    background-color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    font-weight: 700;
    font-size: 0.9rem;
}

.top_modal_close {
    position: absolute;
    top: -8px;
    cursor: pointer;
    color: #fff;
    padding: 5px;
    border-radius: 50%;
    background-color: red;
    right: -7px;
    font-size: 1rem;
}


 
.ddc {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid rgb(0 0 0 / 15%);
    text-align: center;
    /* background-color: cyan; */
}
.ddcLeft{
    border: none;
    border-right: 1px solid rgb(0 0 0 / 15%); ;
}
.ddcAbs {
    /* min-width: 30%; */
    max-width: 50%;
    height: 30px;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #007BC9;
}
.ddcAbsSpan{
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    font-weight: 700;
}
.ddcBtn{
    width: auto;
    height: 18px;
    background-color: #007BC9;
    color: #fff;
    padding-left: 7px;
    padding-right: 7px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 11px;
    box-shadow: 0px 0px 6px grey;
}
.ddcBtn:hover{
    box-shadow: 0px 0px 12px grey ;
}
.faPhone{
    position: relative;
    left: 6px;
    color: #000;
    cursor: pointer;
    font-size: 18px;
}
.deliverDetailsMobile{
    display: none;
}
.ddOuter{
    /* width: 100%;
    height: auto; */
    padding: 12px;
    border-radius: 7px;
    border: 1px solid rgb(0 0 0 / 20%);
}
.ddOtop{
    width: 100%;
    /* height: 250px; */
    justify-content: space-around;
    display: flex;
    border-radius: 6px;
    flex-wrap: wrap;
}
.in1{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.ddOl{
    width: 34%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: rgb(0 0 0 / 3%);
    border: 1px solid rgb(0 0 0 / 5%);
    height: 180px;
    margin-bottom: 14px;
}
.ddOImg{
    width: 140px ;
    height: 76%;
    border-radius: 50%;
    border: 1px solid #000;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ddOR{
    /* width: 60%;
    height: 100%; */
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
    align-items: center;
}
.ddORB{
    /* width: 400px; */
    max-width: 100%;
    min-width: 400px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
}
.ddRow{
    width: 100%;
    height: auto;
    display: flex;
    background-color: yellow;
    padding: 10px;
    background-color: rgb(0 0 0 / 3%);
    border: 1px solid rgb(0 0 0 / 5%);
}
.ddRspan{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.82rem;
    color: #606060;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 4px;
}
.ddRValue{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #000;
}
.ddRspan1{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.82rem;
    color: #606060;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 4px;
}
.ddRValue1{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #000;
}
.ddrStart{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    word-break: break-all;
}
.ddMrker{
    font-size: 1.2rem;
    cursor: pointer;
    color: #ff7200;
}
.ddMrker:hover{
    font-size: 1.3rem;
}
.ddRIc{
    width: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ddOBottom{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: space-around;
    position: relative;
    flex-wrap: wrap;
    box-shadow: 0px 0px 4px #B8B8B8;
}
.EnterpriseSpan{
    position: absolute;
    top: -20px;
    left: 10px;
    padding: 5px 25px;
    color: #fff;
    font-size: 14px;
    font-weight: 900;
    border-radius: 4px;
    background-color: #007BC9;
}
.ddR1{
    min-width: 300px;
    flex: 1;
    min-height: 45px;
    height: fit-content;
    padding: 6px;
    display: flex;
    background-color: rgb(0 0 0 / 3%);
    border: 1px solid rgb(0 0 0 / 5%);
}
.dSuccess{
    color: green;
    font-size: 0.8rem;
    font-weight: 800;
    padding-left: 13px;
}
.dFail{
    color: red;
    font-size: 0.8rem;
    font-weight: 800;
    text-shadow: 0px 0px 14px #dc143c;
}
.dSucess{
    color: green;
    font-size: 0.8rem;
    font-weight: 800;
    /* text-shadow: 0px 0px 81px green; */
}
.specialID{
    font-size: 1.1rem ;
    color: #ff7200;
    font-weight: 800;
    letter-spacing: 1px;
}
.isDeIc{
    color:rgb(0 0 0 / 48%);
    font-size: 15px;
    cursor: pointer;
}
.isDeIcWhite{
    color: #007BC9;
    font-size: 15px;
    cursor: pointer;
}
.base64Img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.alignCenter{
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.btaTTop{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
}
.cBtn{
    width: auto;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    border: none;
    background-color: #007BC9;
    color: #fff;
   border: none;
   box-shadow: 0px 0px 10px grey;
}
.bdaSettingsUI{
    width: 100%;
    height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;
    padding: 8px;
    border-radius: 6px;
    background-color: #fff;
}
.bdaSr{
    width: 100%;
    height: auto;
    display: flex;
    background-color: rgb(0 0 0 / 3%);
    border: 1px solid rgb(0 0 0 / 5%);
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.bdasrHead{
    width: 100%;
    height: auto    ;
}
.bdasrHead>span{
    color: #484848;
    font-size: 1.2rem;
    font-weight: 900;
    text-decoration: underline;
    text-underline-offset: 4px;
}
.bdaSr30{
    width: 100%;
    margin-right: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 10px;
}
.bdaSr30>div{
    width: 100%;
    margin-top: 12px;
    background-color: #fff;
    text-align: left;
}
.bdasrspan{
    font-size: 0.8rem;
    font-weight: 900;
    color: #000;
}
.bdSRInput{
    width: 100%;
    height: 37px;
    padding-left: 12px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    margin-top: 10px;
}
.alignEndBtn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container{
    /* border: 1px solid red; */
    width: 150px;
    overflow: hidden;
    height: 287px;
    margin-left: 20px;
    position: relative;
}

.pfsImg{
   width: 100%;
   margin-top: -12px;
}

.feedback-btn{
    background-color: green;
    color: white;
    padding: 2px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 2px;
}

@media screen and (min-width: 480px) {
    .Delivered-msg{
        display: none;
    }

    .Delivered-bttn{
        display: none;
    }
    
}

@media screen and (max-width: 480px) {

    .pfsImg{
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .deliverDetailsMobile{
        display: none;
    }

    .Delivered-msg{
        position: absolute;
        color: white;
        top: 55%;
        left: 9%;
        font-size: 20px;
        font-weight: 800;
    }

    .Delivered-bttn{
        position: absolute;
        color: white;
        top: 59%;
        width: 100vw;
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 800;
    }
    .Delivered-bttn button{
        width: fit-content;
        border-radius: 8px;
        background-color: white;
        color: #1f539a;
        font-size: 15px;
        font-weight: 800;
        border: none;
        margin-top: 8px;
    }

    .nodatafound {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1f539a;
    }
    .hiddenOnMobile {
        display: none !important;
    }

    .hiddenOnMobileTop {
        display: none !important;
    }
    .image-container {
        width: 100vw;
        height: 100vh;
        /* margin-top: -34px; */
        margin-left: 0px;
    }

    .cover-top{
        position: absolute;
        top: 0%;
        background: #1f539a;
        height: 40px;
        width: 100vw;
        /* border: 2px solid white; */
    }

    .cover-bottom{
        position: absolute;
        bottom: 0%;
        background: #1f539a;
        height: 80px;
        width: 100vw;
    }
    
}

@media screen and (max-width: 360px) {
    .hiddenOnMobile {
        display: none;
    }

    .image-container {
        width: 100vw;
        height: 100vh;
    }

}
.LocationPlotMap{
    width: 100%;
    height: 100px;
}
.ltnNoUnderline{
    color: #ff7200;
    text-decoration: none;
    font-weight: 900;
}
.lMarker{
    font-size: 20px;
    color: #007BC9;
    cursor: pointer;
}
.updateLocations{
    position: absolute;
    top: 65px;
    left:10px;
    width: auto;
    height: 30px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    background-color: #ff7200;
    z-index: 100;
    box-shadow: 0px 0px 12px grey;
}
.forgotPass {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 999911;
    width: 39%;
    height: 270px;
    background-color: #FFE000;
}

.forgotPassInner{
    width: 100%;
    height: 85%;
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 10px;
}
.fMe{
    width: 100%;
    height: 50px;
    display: flex;
}
.fmeIncHgt{
    width: 84%;
    height: 70px;
    display: flex;
    margin-left:auto ;
    margin-right: auto;
    justify-content: space-evenly;
    align-items: center;
}
.fmeOtp{
    width: 90%;
    height: 100%;
}
.fme30{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.fme100{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.fmeSpan{
    font-size: 15px;
    font-weight: 900;
    padding-left: 10px;
    color: #000;
}
.fmeInp{
    width: 98%;
    height: 38px;
    border-radius: 3px;
    border: 1px solid rgb(0 0 0 / 20%);
    padding-left: 10px;
    letter-spacing: 1.2px;
    font-size: 13px;
    font-weight: 900;
    color: #000;
    position: relative;
}
.otpEye{
    position: absolute;
    right: 30px;
    cursor: pointer;
}
.otpEyes{
    position: absolute;
    right: 25px;
    bottom: 14px;
    cursor: pointer;  
}
.alignCent{
    display: flex;
    justify-content: center;
    align-items: center;
}
.fmeBold{
    font-size: 1.8rem;
    font-weight: 900;
}
.fmeSoft{
    font-size: 1rem;
    color: #585858;
    text-align: center;
    font-weight: 900 ! important;
}
.fBtn{
    background-color: #007BC9;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-family: GOTHIC;
    font-size: .9rem;
    min-height: 30px;
    width: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 32%);
}
.alignVertCent{
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.passpin{
    position: relative;
    top: 8px;
    color: #007BC9;
}
.counterElem{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.counterBox{
    width: 15%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    box-shadow: 11px 8px 7px rgb(0 0 0 / 9%);
}
.counterBox:hover{
    cursor: pointer;
    box-shadow: 0px 0px 21px rgb(0 0 0 / 24%);
    background-color: #F8F8F8;

}
.counterLeft{
    width: 40%;
    height: 100%;
}
.counterRight{
    width: 55%;
    height: 100%;
}
.alignCentVert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-end; */
}
.counterSpan{
    text-align: center;
    color: #000;
    font-size: 1vw;
    font-weight: 900;
}
.counterNum{
    text-align: center;
    color: #000;
    font-size: 30px;
    font-weight: 800;
    font-family: 'Tilt Warp', cursive !important;
}
.counterImg{
    width: 80%;
}
.counterMg{
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #fff;
    padding: 13px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 7%);
    border: 1px solid rgb(0 0 0 / 5%);
}
.counterMgR{
    width: 100%;
    height: 60px;
    display: flex;
    margin-bottom: 10px;
    border-left: 3px solid #727376;
    box-shadow: 10px 9px 6px rgb(0 0 0 / 5%);
    background-color: rgb(0 0 0 / 3%);
}
.counter20{
    width: 20%;
    height: 100%;
    position: relative;
}
.flexE{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexS{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.Cspa{
    font-size: 1rem;
    color: #000;
    font-weight: 900;
}
.switch {
    position: relative;
    display: inline-block;
    width: 50px ;
    height: 25px;
    background-color: #787878;
    border-radius: 30px;
    position: relative;
    top: 6px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 5px;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 0px;
    bottom: -6px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    width: 0px;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    width: 0px;
  }
  
  .slider.round:before {
    border-radius: 50%;
    background-color: #fff;
  }
.allButtons{
    width: auto;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    color: #fff;
    background-color: #ff7200;
    border: none;
    box-shadow: 0px 0px 9px rgb(0 0 0 / 20%);
}
.allButtons:hover{
    box-shadow: 0px 0px 12px grey;
}
.embedTag{
    width: 100%;
    height: 100vh;
}
.cashMmeoBtn{
    height: 27px;
    font-size: 0.8rem;
    background-color: #484848;
    position: relative;
    left: 15px;
}
.cashMmeoBtnLeft{
    position: relative;
    left: 1px;
}
.resend{
    position: relative;
    top: 10px;
    text-align: center;
}
#resend-style{
    background-color: #fff;
    width: auto;
    font-size: 15px;
    color: #007BC9;
    font-weight: 800;
    position: relative;
    cursor: pointer; 
    padding-left: 5px;
    text-underline-offset: 4px;
    text-decoration: underline;
    border: none;
}
#resend-styleNew{
    background-color: transparent;
    width: auto;
    font-size: 15px;
    color: #007BC9;
    font-weight: 600;
    position: relative;
    cursor: pointer; 
    padding-left: 5px;
    text-underline-offset: 4px;
    text-decoration: underline;
    border: none; 
}

.bpclYellow{
    background-color: #FFE000;
}

.bpclBlue{
    color: #007BC9;
    font-weight: 800;
}
.footerLogo{
    width: 18px;
    height: 18px;
    position: relative;
    right: 15px;
}
.u100Auto{
    width: 100%;
    height: auto;
    border-radius: 6px;
    flex-direction: column;
    border: 1px solid rgb(0 0 0 / 4%);
}
.pKey{
    width: 100%;
    /* height: 55px; */
    display: flex;
    border-bottom: 1px solid rgb(0 0 0 / 10%);

}
.pHead{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 15%) ;
    color: #000;
    font-size: 0.9rem;
    word-break: break-all;
}
.pCounts{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
}
.pBtn{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    height: 25px;
    background-color: #007BC9;
    color: #fff;
    border: none;
    cursor: pointer;
    position: relative;
    border-radius: 3px;
    margin:10px 9px;
}
.pBtnCross{
    position: absolute;
    top: -6px;
    right: -5px;
    color: red;
    font-size: 14px;
}

.cylinerMap{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
 background-color: #F8F8F8;
    position: relative;
}
.ybg{
    width: 100%;
    height: 100vh;
} 
.cylinderInnerCont{
    /* width: 290px; */
    width: 315px;
    height: auto;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    background-color: #F8F8F8;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cic{
    width: 100%;  
    height: 30px;
    display: flex;
    margin-top: 5px;
}
.pfsImgNew{
    width: 200px;
    /* height: 100px; */
}
.cicCenter{
    /* width: 35%; */
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
}
.cicRight{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    position: relative;
    top: 4px;
}
.cic50{
    width: 100%;
    display: flex;
}
.cicSpa{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* background-color: cyan; */
}
.cicVal{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
}
.cicSpan{
    font-size: 0.8rem;
    font-weight: 900;
    color: #000;
    letter-spacing: 0.6px;
}
.cicValue{
    font-size: 0.8rem;
    font-weight: 900;
    color: #007BC9;
    letter-spacing: 0.6px;
    padding-left: 15px;
    text-decoration: underline;
    text-underline-offset: 5px;
}
.cicGreen{
    font-size: 1rem;
    font-weight: 900;
    color: green;
    letter-spacing: 0.6px;
    padding-left: 15px;
    text-decoration: none !important;
    text-underline-offset: 5px;
    text-shadow: 0px 0px 10px green;
}
.entries{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-wrap: nowrap;
    gap: 10px;
    margin-right: 10px;
}
.entrieSpan{
    color: #000;
    font-size: 13px;
    font-weight: 800;
}
.entries>div{
    width: 50%;
    background-color: #fff;
}
.deliveryPoint{
    color: #000;
    text-decoration: underline !important;
    text-underline-offset: 4px;
}
.tripDetailsbox {
    align-items: center;
    background-color: #007BC9;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 10px grey;
    display: flex;
    height: auto;
    justify-content: center;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 33px;
    width: 160px;
    z-index: 100;
}
::-webkit-scrollbar {
    height: 4px;              /* height of horizontal scrollbar ← You're missing this */
    width: 4px;               /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
  .logoHidee{
    display: none;
}
@media only screen and (max-width: 1200px) {
    .forgotPass {
        z-index: 999911;
        width: 55%;
    }
}
@media only screen and (max-width: 990px) {
.twoCalend {
width: 40%;
height: 100%;
display: flex;
margin-top: 20px;
}
.ddR1Display{
    display: none;
}
.forgotPass {
    z-index: 999911;
    width: 65%;
}
}
@media only screen and (max-width: 800px) {
.udeta{
display: none;
}

}
@media only screen and (max-width: 700px) {
    .forgotPass {
        z-index: 999911;
        width: 72%;
    }
    .myBars{
        margin-right: 10px;
        display: block;
    }
    .trackingMap{
        width: 100% ;
        height: 100vh ;
    }
    .deliverDetails{
        display: none;
    }
    .logoHidee{
        display: block;
    }
    .deliverDetailsMobile{
        /* display: none; */
        width: 98%;
        /* height: 55px; */
        position: fixed;
        bottom: 1px;
        z-index: 100;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0px 0px 18px #A9A9A9;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }
    .dd50{
        width: 100%;
        height: 100%;
        display: flex;
    }
    .ddc {
        width: 50%;
        min-height: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        border-left: 1px solid rgb(0 0 0 / 1%);
    }
    .faPhone{
        position: relative;
        left: 6px;
        color: #000;
        cursor: pointer;
        font-size: 18px;
    }
    .ddcAbs {
        width: 70%;
        height: 30px;
        background-color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 8px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        background-color: #007BC9;
    }
    .ddcSpan {
        font-size: 11px;
        font-weight: 900;
        text-decoration: underline;
        text-underline-offset: 4px;
    }
    .ddcVal {
        font-size: 11px;
        color: #007BC9;
        font-weight: 900 !important;
    }
    .ddcBtn{
        width: auto;
        height: 22px;
        background-color: #007BC9;
        color: #fff;
        padding-left: 12px;
        padding-right: 12px;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-size: 9px;
        box-shadow: 0px 0px 6px grey;
    }
    .ddcAbsSpan {
        font-size: 9px;
        font-weight: 500;
        color: #fff;
        font-weight: 700;
    }

    .customModasl {
        position: absolute;
        top: 25%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 999911;
        width: 95%;
        height: 265px;
        background-color: #fff;
        border-radius: 8px;
        border: 3px solid #007bc9;
    }
    .otpfield {
        border: 1px solid silver;
        box-shadow: inset 1px 1px 10px rgb(0 0 0 / 7%);
        font-size: 20px;
        height: 100%;
        text-align: center;
        width: 15%;
    }
    .main-content>.content-body>.contentpanel {
        /* margin: 8px 15px; */
        box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
        background: #F8F8F8;
        padding: 0px;
        min-height: 90vh;
        height: 100%;
        transition: all 0.4s;
    }

    .cust-row>.cust-col-10 {
        width: 100%;
        position: relative;
        /* padding: 5px 10px; */
    }

    .sidebar.open+.content-body {
        margin-left: 0px;
    }

    .sidebar.open {
        left: -180px;
        margin-top: 5px;
    }

    .sidebar {
        background-color: #FFF;
        width: 10px;
        position: fixed;
        top: 64px;
        bottom: 0px;
        left: -180px;
        z-index: 100;
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 0.4s;
    }

    .sidebar.open.mobile {
        width: 180px;
        left: 0px;
        margin-top: 5px;
        z-index: 102;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 31%);
    }

    .webView {
        display: none;
    }

    .mobileView {
        display: block;
    }

    .searcBox {
        width: 100%;
    }

    #menuToggle {
        display: none;
    }

    .loginLeft {
        display: none;
    }

    .contentpanel h1.title {
        font-size: 0.8rem;
        margin: 0;
    }

    .loginDiv1 {
        width: 85%;
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }

    .btnforgotBtn {
        width: auto;
        height: 33px;
        background-color: #007BC9;
        color: #fff;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 7px;
        font-size: 0.8rem;
    }

    .btnforgotBtn:hover {
        width: auto;
        height: 33px;
        background-color: transparent;
        color: #fff;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 7px;
        font-size: 0.8rem;
    }

    .eyeIcon {
        position: absolute;
        top: 40px;
        color: #727376;
        cursor: pointer;
    }

    .dateInput {
        width: 65%;
    }

    .searchDiv {
        width: 100%
    }

    .wid30 {
        width: 30%;
    }

    .bdaH00>span {
        font-size: 0.55rem;
        font-weight: 700;
        color: #fff;
    }

    .bdaH2>span {
        font-size: 0.55rem;
        font-weight: 700;
        color: #fff;
    }

    .bdaSpan {
        font-size: 0.55rem !important;
        color: #000 !important;
        font-weight: 600 !important;
    }

    .bdaSpanOrange {
        font-size: 0.75rem !important;
        color: #007BC9 !important;
        font-weight: 600 !important;
    }

    .startBtn {
        background-color: green;
        color: #fff;
        border: none;
        font-size: 0.45rem;
        border-radius: 3px;
        cursor: pointer;
    }

    .editIcon {
        font-size: 0.8rem;
        cursor: pointer;
        color: green;
    }

    .trashIcon {
        font-size: 0.8rem;
        cursor: pointer;
        color: red;
    }

    .modifyHgt {
        /* height: auto; */
        /* padding: 0px; */
        position: relative;
    }

    .paginationBtn {
        width: auto;
        height: 25px;
        position: relative;
        top: 0px;
        background-color: #007BC9;
        color: #fff;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        margin-right: 12px;
        font-size: 0.55rem;
        text-align: center;
    }

    .header30 {
        /* display: none; */
    }
    .myMapBtnGrey {
        width: auto ;
        height: 35px;
        background-color: #007BC9;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.8rem;
        margin: 4px;
        padding: 0px 10px;
    }

    .myMapB {
        width: 20% !important;
        height: 20px !important;
    }

    .myMapBtn {
        position: absolute;
        left: 54%;
        top: 86px;
        font-size: 0.6rem;
        z-index: 111;
    }
    .twoCalend {
        width: 40%;
        height: 100%;
        display: flex;
        margin-top: 20px;
    }
}
@media only screen and (max-width: 600px) {
    .forgotPass {
        z-index: 999911;
        width: 90%;
    }
    }


   